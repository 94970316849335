import {
  Button,
  Flex,
  Text,
  Heading,
  Stack,
  ButtonGroup,
  Icon,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { BsLightningCharge } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { TbMessageChatbot } from "react-icons/tb";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AssistantType = ({ voices, voiceSsts }) => {
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const assistantTypes = [
    {
      type: "simple",
      children: TbMessageChatbot,
      title: "Chat",
      subheading:
        "Chat Assistants are optimized for fetching and answering your customers’ queries as Chatbots.",
    },
    {
      type: "phone",
      children: FaPhone,
      title: "Phone",
      subheading:
        "Conversational Voice AI Agents that can be deployed over a phone number or on web",
    },
    {
      type: "realtime_openai",
      children: BsLightningCharge,
      title: "OpenAI Realtime",
      subheading: "Advanced Voice from OpenAI Realtime is in BETA mode",
    },
  ];
  const currentAssistantType = assistantTypes.find(
    (type) => type.type === watch("assistant_type")
  );

  return (
    <Flex direction={"column"} gap={4} pl={4} mb={4}>
      <Flex
        fontSize={"md"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
      >
        <Text>Assistant Type : </Text>
        <ButtonGroup spacing={0} isAttached>
          {assistantTypes.map(({ children: AssistantIcon, type, title }) => (
            <Button
              title={type}
              onClick={() => {
                const promptToSet = watch("systemPrompt")
                  ? watch("systemPrompt")
                  : `As an AI assistant built by a team of engineers at ${
                      myDetails?.branding?.agency_name || "Algoscale"
                    }, please answer the user query with the context provided.`;

                setValue("assistant_type", type);
                setValue("has_human_agent", false);

                setValue("templatePhonePrompt", undefined);
                setValue("custom_voice", false);
                setValue("systemPrompt", promptToSet);
                setValue(
                  "llmModel",
                  type !== "realtime_openai" && {
                    value: "gpt-3.5-turbo-0125",
                    label: "GPT-3.5-Turbo-0125",
                  }
                );

                const voice = voices.find(
                  (voice) => voice.language_group === "english"
                );
                setValue(
                  "voiceLanguages",
                  voiceSsts
                    .filter((item) => item.locale_code === "en-US")
                    .map((language) => ({
                      label: language.name,
                      value: language.locale_code,
                      id: language.id,
                      language_group: language.language_group,
                      speaker: voice && {
                        label: `${voice.name} (${voice.credits}) credits`,
                        value: voice.id,
                        voicetts_provider: voice.voicetts_provider,
                      },
                      voicestt_provider: language.voicestt_provider,
                    }))
                );

                setValue("voice", ["simple", "phone"].includes(type));
              }}
              colorScheme={
                type === watch("assistant_type") ? "gray" : undefined
              }
              isActive={type === watch("assistant_type")}
              key={type}
              leftIcon={<AssistantIcon />}
            >
              {title}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
      {currentAssistantType ? (
        <Stack spacing={1}>
          <Heading fontSize={"md"}>{currentAssistantType.title}</Heading>
          <Text>{currentAssistantType.subheading}</Text>
        </Stack>
      ) : null}
    </Flex>
  );
};

export default AssistantType;
