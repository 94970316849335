import { Checkbox, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const CustomCheckbox = ({ onChange, children, ...props }) => {
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const borderColor = useColorModeValue("black", "white");
  return (
    <Checkbox
      colorScheme={buttonColorScheme}
      border={borderColor}
      _checked={{ borderColor: "transparent", boxShadow: "none" }}
      onChange={onChange}
      {...props}
    >
      {children}
    </Checkbox>
  );
};

export default CustomCheckbox;
