import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import React from "react";

export default function GoalLlm({ onClose, nodeForm, onSubmit, hasFinished, onBack }) {
  const { register, watch } = nodeForm.form;
  const { data } = watch();
  return (
    <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <Alert status="info">
            <AlertIcon />
            If enabled, the Flow will stay at the current step until the goal
            description has been achieved.
          </Alert>
          <FormControl>
            <Flex justifyContent={"flex-start"} alignItems={"center"} gap={3}>
              <Checkbox {...register("data.goal.is_enabled")} />
              <FormLabel>Goal</FormLabel>
            </Flex>
            <Input
              placeholder="Write your goal"
              isDisabled={!data?.goal?.is_enabled}
              {...register("data.goal.value")}
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" colorScheme={hasFinished ? "blue" : "yellow"}>
          {hasFinished ? "Finish" : "Next"}
        </Button>
      </ModalFooter>
    </form>
  );
}
