import {
  Button,
  FormControl,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import FormLabelWithInfoTooltip from "../../FormLabelWithInfoTooltip";
import FormSelect from "../../FormSelect";

export default function InitialForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { watch, setValue } = widgetForm;

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <FormControl>
            <FormLabelWithInfoTooltip
              label={"Inital Form"}
              tip={"This form will be pushed on the starting of the chat."}
            />
            <FormSelect
              selectedForm={watch("configuration.form")}
              onChange={(selectedForm) => {
                setValue("form", selectedForm?.value || "");
              }}
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
