import { Flex } from "@chakra-ui/react";
import React from "react";
import { components } from "react-select";
const { Option } = components;
const ImageIconWidgetOption = (props) => {
  const { src } = props.data;  
  return (
    <Option {...props}>
      <Flex justifyContent={"flex-start"} gap={3} alignItems={"center"}>
        <img src={src} width={15} />
        {props.data.label}
      </Flex>
    </Option>
  );
};

export default ImageIconWidgetOption;
