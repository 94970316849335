import { addEdge, applyEdgeChanges, applyNodeChanges } from "@xyflow/react";
import { useCallback, useState } from "react";
import useConversationFlow from "./useConversationFlow";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function useAppFlowNodes(flowId) {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [flowName, setFlowName] = useState(null);

  const { status, editFlow, getFlowById } = useConversationFlow();

  const updateFlow = async ({ flowId, nodes, edges, flowName, updateFn }) => {
    try {
      const payload = {
        name: flowName,
        num_nodes: nodes.length,
        num_edges: edges.length,
        flow: {
          nodes,
          edges,
        },
      };

      const response = await editFlow(flowId, payload);
      updateFn();
      
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect = useCallback(
    async (params, data, nodes, edges, flowName) => {
      const edgesData = addEdge(
        { ...params, type: "customEdge", data },
        edges
      );

      await updateFlow({
        flowId,
        nodes,
        edges: edgesData,
        flowName,
        updateFn: () => setEdges(edgesData),
      });
    },
    []
  );

  const onAddNewNode = useCallback(async (values, nodes, edges, flowName, type=null) => {
    const node = makeNodeByData(values, type, nodes);
    const newNodes = [...nodes, node];

    await updateFlow({
      flowId,
      nodes: newNodes,
      edges,
      flowName,
      updateFn: () => setNodes(newNodes),
    });
  }, []);

  const onEditNode = useCallback(async (values, nodes, edges, flowName, type=null) => {
    const updatedNodeValue = {
      ...values,
      data: { ...values.data, label: values.data.name },
      type: type,
    };
    const updatedNodes = nodes.map((node) =>
      node.id === values.id ? updatedNodeValue : node
    );

    await updateFlow({
      flowId,
      nodes: updatedNodes,
      edges,
      flowName,
      updateFn: () => setNodes(updatedNodes),
    });
  }, []);

  const onEditEdge = useCallback(async (selectedEdge, edgeProperties, nodes, edges, flowName) => {
    const updatedEdgeValue = { ...selectedEdge, data: edgeProperties }
    const updatedEdges = edges.map((edge) => edge.id === selectedEdge.id ? updatedEdgeValue : edge)

    await updateFlow({
      flowId,
      nodes,
      edges: updatedEdges,
      flowName,
      updateFn: () => setEdges(updatedEdges),
    });
  }, [])

  const onDeleteEdge = useCallback(async (id, edges, nodes, flowName) => {
    const filteredEdges = edges.filter((edge) => edge.id !== id);

    await updateFlow({
      flowId,
      nodes,
      edges: filteredEdges,
      flowName,
      updateFn: () => setEdges(filteredEdges),
    });
  }, []);

  const operations = {
    onAddNewNode,
    onDeleteEdge,
    onEditNode,
    onEditEdge,
  };

  const makeNodeByData = (values, type, nodes) => {
    const id = `${Date.now()}`;

    let x = 100;
    let y = 100;

    if (nodes.length > 0) {
      const maxYNode = nodes.reduce((prev, current) =>
        prev.position.y > current.position.y ? prev : current
      );

      x = maxYNode.position.x + 200;
      y = maxYNode.position.y + 150;
    }

    return {
      ...values,
      data: { ...values.data, label: values.data.name },
      id,
      type: type,
      position: { x, y },
    };
  };

  const handleFlowAutoFill = async (id) => {
    try {
      const response = await getFlowById(id);
  
      const { name, flow } = response.data;
      setFlowName(name);
      setNodes(flow?.nodes || []);
      setEdges(flow?.edges || []);
    } catch (error) {
      toast.error(error?.message || "An unexpected error occurred");
      navigate("/conversation-flow");
    }
  };

  return {
    onConnect,
    onEdgesChange,
    onNodesChange,
    handleFlowAutoFill,
    edges,
    nodes,
    flowName,
    operations,
    status,
  };
}
