import {
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";

export default function DataSourceForm({ nodeForm }) {
  const baseUrl = getHostDomain();
  const { setValue, watch } = nodeForm.form;
  const { data = {} } = watch();
  const [dataSourceOptions, setDataSourceOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataSources = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        `${baseUrl}/api/v1/datasource/list?size=100`,
        "GET"
      );

      const options = response?.data?.items?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setDataSourceOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataSources();
  }, []);

  const selectedDataSourceMap = (data?.data_sources || []).reduce(
    (prev, current) => {
      prev[current] = true;
      return prev;
    },
    {}
  );

  return (
    <>
      <FormControl>
        <FormLabel>Data sources</FormLabel>
        <Select
          options={dataSourceOptions}
          isMulti
          value={dataSourceOptions.filter(
            (option) => option.value in selectedDataSourceMap
          )}
          onChange={(selectedOptions) => {
            setValue(
              "data.data_sources",
              selectedOptions.map((selectedOption) => selectedOption.value)
            );
          }}
          isLoading={loading}
        />
      </FormControl>
    </>
  );
}
