import {
  Box,
  Button,
  Table as ChakraTable,
  Checkbox,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { PiPlusCircleBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { getHostDomain } from "../../utils/utils";

const AddContact = (props) => {
  const baseUrl = getHostDomain();

  const {
    isAddContactOpen,
    onAddContactClose,
    buttonColorScheme,
    operation,
    setOperation,
    selectedRow,
    setTableData,
    getCampaignById,
    setSelectedRow,
    getAllCampaign,
  } = props;

  const tableColumns = ["", "Name", "Email", "Contact Number", "Actions"];

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [status, setStatus] = useState("loading");
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);

  const dependency = [paginationSize, page];
  const [allContactForCampaign, setAllContactForCampaign] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const getAllContactForCampaign = async () => {
    try {
      const endpoint = `/api/v1/campaign_contact/list/${selectedRow.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const newData = response.data.items.map((item) => {
        setAllContactForCampaign((prevContacts) => ({
          ...prevContacts,
          [item.id]: true,
        }));
        return {
          ...item,
          isChecked: false,
          isAlreadyAdded: true,
        };
      });
      return newData;
    } catch (e) {
      console.log(e);
    }
  };

  const getContactForCampaign = async () => {
    try {
      const endpoint = `/api/v1/campaign_contact/list/${selectedRow.id}?size=${paginationSize}&page=${page}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const newData = response.data.items.map((item) => ({
        ...item,
        isChecked: false,
        isAlreadyAdded: true,
      }));
      return newData;
    } catch (e) {
      console.log(e);
    }
  };

  const getContactList = useCallback(async () => {
    try {
      const endpoint = `/api/v1/contact/list?size=${paginationSize}&page=${page}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const newData = [];
      response.data.items.forEach((item) => {
        newData.push(item);
      });

      setContactList(newData);
      setPages(response.data.pages);
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("failure");
    }
  }, dependency);

  const AddContactToCampaign = async (contact, ind) => {
    try {
      const endpoint = `/api/v1/campaign_contact`;
      const urlHit = baseUrl + endpoint;
      const body = {
        campaign_id: selectedRow?.id,
        contact_id: contact?.id,
        status: "not_attempted",
      };
      const response = await fetchController(urlHit, "POST", body);
      await getAllContactForCampaign();
      await getAllCampaign();
      await toast.success("Added Contacts to Campaign");
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("failure");
    }
  };

  const hanldeMultipleAddContactToCampaign = async () => {
    try {
      const endpoint = `/api/v1/campaign_contact/${selectedRow.id}`;
      const urlHit = baseUrl + endpoint;
      const body = selectedContact.map((contact) => contact.id);

      const response = await fetchController(urlHit, "PUT", body);
      setTableData((prevData) => {
        const newData = [...prevData];
        newData[selectedRow.index].contacts = [
          ...newData[selectedRow.index].contacts,
          ...body,
        ];
        return newData;
      });
      await getAllContactForCampaign();
      toast.success("Added Contacts to Campaign !");
      setStatus("success");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedContact([]);
    setShowMore(false);

    getContactForCampaign();
    getContactList();
  }, dependency);

  useEffect(() => {
    getAllContactForCampaign();
  }, []);

  return (
    <>
      <Modal
        isOpen={isAddContactOpen}
        onClose={() => {
          setSelectedRow(null);
          onAddContactClose();
        }}
        blockScrollOnMount={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxW="60vw">
          <ModalHeader
            style={{ fontWeight: 700, fontSize: "18px" }}
            alignContent="center"
          >
            <Flex justifyContent="space-between" mr="5%">
              Add Contacts
            </Flex>
            <Flex
              style={{ fontWeight: 400, fontSize: "14px" }}
              justifyContent="space-between"
              alignContent="center"
            >
              <Text>Add Contacts from here.</Text>
              <Button
                leftIcon={<FaPlus />}
                style={{
                  backgroundColor: "#3B82F6",
                  color: "#FFFFFF",
                }}
                type="submit"
                onClick={async () => {
                  if (selectedContact.length > 0) {
                    setStatus("loading");
                    await hanldeMultipleAddContactToCampaign();
                    setSelectedContact([]);
                  } else toast.error("Please select the contact");
                }}
                isDisabled={selectedContact.length === 0}
              >
                Bulk Add ({selectedContact.length})
              </Button>
            </Flex>
          </ModalHeader>
          <ModalCloseButton alignItems="center" />
          <ModalBody>
            <ChakraTable width="100%">
              <Thead>
                <Tr>
                  {tableColumns.map((column, index) => (
                    <Th
                      textAlign="left"
                      borderBottom="1px"
                      borderColor="#E4E4E7"
                      style={{ textWrap: "nowrap" }}
                      key={column}
                    >
                      {index === 0 ? (
                        <Checkbox
                          onChange={(e) => {
                            setSelectAll(e.target.checked);
                            setContactList((prevContactData) => {
                              const newData = prevContactData.map((row) => {
                                const toBeDisable =
                                  allContactForCampaign[row.id];
                                if (e.target.checked && !toBeDisable) {
                                  setSelectedContact((prevData) => {
                                    const alreadyAdded = prevData.some(
                                      (contact) => contact.id === row.id
                                    );
                                    if (alreadyAdded) return prevData;
                                    return [...prevData, row];
                                  });
                                }
                                if (toBeDisable) return { ...row };
                                return {
                                  ...row,
                                  isChecked: e.target.checked,
                                };
                              });
                              if (!e.target.checked) setSelectedContact([]);
                              return newData;
                            });
                          }}
                          isChecked={selectAll}
                        ></Checkbox>
                      ) : (
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          {column}
                        </Box>
                      )}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {status === "loading" ? (
                  <Loader />
                ) : (
                  <>
                    {contactList?.map((contact, index) => (
                      <Tr>
                        <Td>
                          <Checkbox
                            onChange={(e) => {
                              setContactList((prevContactData) => {
                                const newData = [...prevContactData];
                                newData[index].isChecked = e.target.checked;
                                return newData;
                              });
                              setSelectedContact((prevSelected) => {
                                const isAlreadySelected = prevSelected.some(
                                  (item) => item.id === contact.id
                                );
                                if (isAlreadySelected) {
                                  return prevSelected.filter(
                                    (item) => item.id !== contact.id
                                  );
                                } else {
                                  return [...prevSelected, contact];
                                }
                              });
                            }}
                            disabled={allContactForCampaign[contact.id]}
                            isChecked={contact.isChecked}
                          ></Checkbox>
                        </Td>
                        <Td>
                          {contact.first_name} {contact.last_name}
                        </Td>
                        <Td>{contact.email}</Td>
                        <Td>{contact?.channels?.telephone}</Td>
                        <Td>
                          <Flex justifyContent="center" alignItems="center">
                            <Tooltip
                              label={
                                !allContactForCampaign[contact.id] &&
                                "Add Contact"
                              }
                            >
                              <IconButton
                                isDisabled={allContactForCampaign[contact.id]}
                                size="lg"
                                icon={<PiPlusCircleBold />}
                                variant="ghost"
                                colorScheme="blue"
                                onClick={async () => {
                                  setStatus("loading");
                                  await AddContactToCampaign(contact, index);
                                  setSelectedContact((prevSelectedContact) =>
                                    prevSelectedContact.filter(
                                      (prevContact) =>
                                        prevContact.id !== contact.id
                                    )
                                  );
                                }}
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </Tbody>
            </ChakraTable>
          </ModalBody>
          <ModalFooter>
            <Box>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={4}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={contactList.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
              <Box>
                <Flex float="right" gap={4}>
                  <Button
                    onClick={() => {
                      setSelectedRow(null);
                      onAddContactClose();
                    }}
                    variant="outline"
                    width="104px"
                  >
                    Close
                  </Button>
                </Flex>
              </Box>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddContact;
