import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function WidgetTour({ onCloseWidgetTour }) {
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  return (
    <TourLayout onCloseTour={onCloseWidgetTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What is Widget?
        </Text>
        <Text>
          A widget is an interface to let your users interact with your
          assistants. You could embed it as an iframe on your webpage, use it as
          a virtual chatbot, deploy it on WhatsApp or use Twilio to let your
          customers interact with your voice assistants
        </Text>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Steps to add a new Widget:
        </Text>
        <UnorderedList>
          <ListItem>
            Select the Provider where you want to deploy the widget.
          </ListItem>
          <ListItem>Configure the Widget</ListItem>
          <ListItem>Select the Assistant that powers the Widget.</ListItem>
        </UnorderedList>
      </Box>
    </TourLayout>
  );
}
