import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import Select from "react-select";
import CommonSlider from "../../utils/CommonSlider";

export default function LLmModel({
  editData,
  watch,
  register,
  setValue,
  errors,
}) {
  const baseUrl = getHostDomain();

  const [llmModels, setLlmModels] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSliderInputChange = (event, min, max, setValue, name) => {
    const value = Number(event.target.value);
    if (!isNaN(value) && value >= min && value <= max) {
      setValue(name, value);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const responseLLms = await fetchController(
          baseUrl + "/api/v1/llmmodel/list"
        );

        const llmModelOptions = responseLLms.data.items
          .filter((llmModel) =>
            llmModel.attributes?.supported_assistant_types.includes(
              watch("assistant_type")
            )
          )
          .map((llmModel) => ({
            value: llmModel.llm_model_label,
            label: llmModel.name,
            supportedAssistantTypes:
              llmModel.attributes?.supported_assistant_types,
          }));

        setLlmModels(llmModelOptions);
        if (llmModelOptions?.length) {
          setValue("llmModel", llmModelOptions[0] || null, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }

        if (editData) {
          const selectedLlm = llmModelOptions.find(
            (llm) => llm.value === editData.llm_model
          );
          if (selectedLlm) setValue("llmModel", selectedLlm);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [watch("assistant_type"), editData]);

  return (
    <FormControl isInvalid={errors.llmModel}>
      <FormLabel htmlFor="llmModel">LLM Model</FormLabel>
      <Select
        placeholder="Select LLM Model"
        value={watch("llmModel")}
        isLoading={loading}
        options={llmModels}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures it displays above other components
        }}
        {...register("llmModel", {
          required: "LLM Model is required",
        })}
        onChange={(selectedOption) => {
          setValue("llmModel", selectedOption || null, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
      />
      {errors.llmModel && (
        <FormErrorMessage>{errors.llmModel.message}</FormErrorMessage>
      )}

      <Grid mt="2">
        <CommonSlider
          watch={watch}
          label="LLM Temperature"
          setValue={setValue}
          min={0}
          max={2}
          defaultValue={1}
          name="attributes.llm_temperature"
          step={0.05}
          toolTipLabel="Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic"
          isInputDisabled={false}
          width="10%"
          handleSliderInputChange={handleSliderInputChange}
        />
      </Grid>
    </FormControl>
  );
}
