import {
  Box,
  Table as ChakraTable,
  Checkbox,
  Code,
  Flex,
  Icon,
  IconButton,
  Switch,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlinePersonOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbMinusVertical } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import Paginator from "../../utils/Loader/Paginator";
import { getHostDomain } from "../../utils/utils";
import ConfirmationModal from "../Modals/Confirmation";
import AddContact from "./AddContact";
import AddEditContact from "./EditContact";
import { IoTimeOutline } from "react-icons/io5";
import { LuTimerReset } from "react-icons/lu";
import { TbProgressCheck } from "react-icons/tb";
import { MdMotionPhotosPaused } from "react-icons/md";
import { GiSandsOfTime } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import { BiBookAdd } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import CustomPieChart from "./CustomPieChart";

ChartJS.register(ArcElement, Legend);

const CampaignTable = (props) => {
  const {
    paginationSize,
    setPaginationSize,
    tableData,
    buttonColorScheme,
    setPage,
    page,
    setTableData,
    pages,
    onAddEditCampaignOpen,
    setSelectedRow,
    selectedRow,
    updateCampaignById,
    getAllCampaign,
    setSelectedCampaigns,
    getCampaignById,
  } = props;

  const baseUrl = getHostDomain();
  const dependency = [page, paginationSize];
  const {
    isOpen: isDeleteConfirmationModalOpen,
    onOpen: onDeleteConfirmationModalOpen,
    onClose: onDeleteConfirmationModalClose,
  } = useDisclosure();

  const {
    isOpen: isEditContactOpen,
    onOpen: onEditContactOpen,
    onClose: onEditContactClose,
  } = useDisclosure();

  const {
    isOpen: isAddContactOpen,
    onOpen: onAddContactOpen,
    onClose: onAddContactClose,
  } = useDisclosure();
  const [operation, setOperation] = useState(null);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const columns = [
    "",
    "Enable",
    "Campaign",
    "Widget",
    "Start Date",
    "Contacts",
    "Status",
    "Actions",
  ];

  const statusIconsData = {
    not_attempted: {
      icon: <IoTimeOutline />,
      toolTipLabel: "Not Attempted",
      color: "gray",
    },
    to_be_run: { icon: <LuTimerReset />, toolTipLabel: "To Be Run", color: "" },
    in_progress: {
      icon: <TbProgressCheck />,
      toolTipLabel: "In Progress",
      color: "yellow",
    },
    to_be_retried: {
      icon: <GiSandsOfTime />,
      toolTipLabel: "To Be Retried",
      color: "blue",
    },
    paused: {
      icon: <MdMotionPhotosPaused />,
      toolTipLabel: "Paused",
      color: "grey",
    },
    failed: {
      icon: <MdOutlineCancel />,
      toolTipLabel: "failed",
      color: "red",
    },
    completed: {
      icon: <FaRegCircleCheck />,
      toolTipLabel: "Completed",
      color: "green",
    },
    cancelled: {
      icon: <MdOutlineCancel />,
      toolTipLabel: "Cancelled",
      color: "red",
    },
    not_started: {
      icon: <IoTimeOutline />,
      toolTipLabel: "Not Started",
      color: "gray",
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const handleDelete = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/campaign/${selectedRow.id}`,
        "DELETE"
      );
      getAllCampaign();
      onDeleteConfirmationModalClose();
      toast.success("Campaign Deleted.");
    } catch (e) {
      toast.error("Campaign didn't Delete.");
      console.log(e);
    } finally {
      setSelectedRow(null);
    }
  };

  const handleDeleteCampaign = () => {
    onDeleteConfirmationModalOpen();
  };

  useEffect(() => {
    setSelectedAll(false);
  }, dependency);

  return (
    <Box>
      <Box shadow="sm" borderRadius="md" overflowY="auto">
        <ChakraTable width="100%">
          <Thead>
            <Tr height="44px" backgroundColor="#FAFAFA">
              {columns.map((column, index) => (
                <Th
                  textAlign="left"
                  borderBottom="1px"
                  borderColor="#E4E4E7"
                  style={{ textWrap: "nowrap" }}
                  key={column}
                >
                  {index === 0 ? (
                    <Checkbox
                      onChange={(e) => {
                        setSelectedAll(e.target.checked);
                        setTableData((prevTableData) => {
                          const newData = prevTableData.map((row) => ({
                            ...row,
                            isChecked: e.target.checked,
                          }));
                          if (e.target.checked) setSelectedCampaigns(newData);
                          else setSelectedCampaigns([]);
                          return newData;
                        });
                      }}
                      isChecked={isSelectedAll}
                    ></Checkbox>
                  ) : (
                    column
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableData?.map((row, index) => {
              const contactStatusCounts = row?.contact_status_counts || {};
              const pieChartStatusContact = Object.entries(contactStatusCounts)
                .map(([status, value]) => {
                  const contactStatusIcon = statusIconsData[status];
                  return {
                    value,
                    label: contactStatusIcon.toolTipLabel,
                    color: contactStatusIcon.color,
                  };
                })
                .filter((pieItem) => pieItem.value);
              const contactCampaignCompletionRatio = row?.contacts?.length
                ? `${contactStatusCounts?.completed} / ${row?.contacts?.length}`
                : null;
              return (
                <Tr
                  key={row.id}
                  borderBottom="1px"
                  borderColor="#E4E4E7"
                  bg={row.status === "completed" && "gray.200"}
                >
                  <Td>
                    <Checkbox
                      onChange={(e) => {
                        setTableData((prevTableData) => {
                          const newData = [...prevTableData];
                          newData[index].isChecked = e.target.checked;
                          return newData;
                        });
                        setSelectedCampaigns((prevSelected) => {
                          const isAlreadySelected = prevSelected.some(
                            (item) => item.id === row.id
                          );
                          if (isAlreadySelected) {
                            return prevSelected.filter(
                              (item) => item.id !== row.id
                            );
                          } else {
                            return [...prevSelected, row];
                          }
                        });
                      }}
                      isChecked={row?.isChecked}
                    ></Checkbox>
                  </Td>
                  <Td>
                    <Switch
                      onChange={async (e) => {
                        setTableData((prevTableData) => {
                          const newData = [...prevTableData];
                          newData[index].enabled = e.target.checked;
                          return newData;
                        });
                        await updateCampaignById({
                          ...row,
                          enabled: e.target.checked,
                        });

                        const updatedRow = await getCampaignById(row.id);
                        setTableData((prevTableData) => {
                          const newData = [...prevTableData];
                          newData[index] = {
                            ...updatedRow,
                            widget_name: row.widget_name,
                            contacts: row.contacts,
                            contact_status_counts: row.contact_status_counts,
                          };
                          return newData;
                        });
                      }}
                      isChecked={row.enabled}
                    />
                  </Td>
                  <Td>
                    <Tooltip label={statusIconsData[row.status].toolTipLabel}>
                      <IconButton
                        icon={statusIconsData[row.status].icon}
                        variant="ghost"
                        _hover={{ bg: "transparent", cursor: "default" }}
                        color={statusIconsData[row.status].color}
                      />
                    </Tooltip>
                    {row.name}
                  </Td>
                  <Td>
                    <IconButton
                      icon={<TiMessages />}
                      variant="ghost"
                      _hover={{ bg: "transparent", cursor: "default" }}
                      color="#2563EB"
                    />
                    {row.widget_name}
                  </Td>
                  <Td>
                    {moment(row.start_time.split("T")[0]).format(
                      "MMM Do, YYYY"
                    )}
                  </Td>
                  <Td>
                    <Flex
                      bg="#EFF6FF"
                      borderRadius="4px"
                      width="64px"
                      justifyContent="center"
                    >
                      <IconButton
                        icon={
                          <Flex alignItems="center" gap={2}>
                            <MdOutlinePersonOutline size={25} />
                            {row?.contacts?.length}
                          </Flex>
                        }
                        colorScheme="blue"
                        variant="ghost"
                        _hover={{ bg: "transparent" }}
                        onClick={() => {
                          setOperation("editing");
                          setSelectedRow({ ...row, index });
                          onEditContactOpen();
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td justifyItems="flex-start">
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      position={"relative"}
                      gap={1}
                    >
                      <CustomPieChart data={pieChartStatusContact} size={40} />
                      <Text>{contactCampaignCompletionRatio}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex gap={2} alignItems="center">
                      <IconButton
                        icon={<FiEdit />}
                        variant="ghost"
                        color="#3B82F6"
                        onClick={(e) => {
                          setSelectedRow({ ...row, index });
                          onAddEditCampaignOpen();
                        }}
                      />
                      <IconButton
                        icon={<RiDeleteBin6Line />}
                        variant="ghost"
                        color="#EF4444"
                        onClick={(e) => {
                          setSelectedRow({ ...row });
                          handleDeleteCampaign();
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </Box>
      <Flex justifyContent="flex-end" marginBlock={2} gap={2}>
        <PaginationSizeSelect
          paginationSize={paginationSize}
          setPaginationSize={setPaginationSize}
          tableLength={tableData?.length}
        />
        <Paginator
          colorScheme={buttonColorScheme}
          onChangePage={setPage}
          page={page}
          pages={pages}
        />
        <ConfirmationModal
          table={"campaigns"}
          isOpen={isDeleteConfirmationModalOpen}
          onConfirm={handleDelete}
          onClose={onDeleteConfirmationModalClose}
        />
      </Flex>
      {isEditContactOpen && (
        <AddEditContact
          operation={operation}
          selectedRow={selectedRow}
          setOperation={setOperation}
          isEditContactOpen={isEditContactOpen}
          onEditContactClose={onEditContactClose}
          buttonColorScheme={buttonColorScheme}
          getCampaignById={getCampaignById}
          setTableData={setTableData}
          setSelectedRow={setSelectedRow}
          getAllCampaign={getAllCampaign}
        />
      )}{" "}
      {isAddContactOpen && (
        <AddContact
          operation={operation}
          selectedRow={selectedRow}
          setOperation={setOperation}
          isAddContactOpen={isAddContactOpen}
          onAddContactClose={onAddContactClose}
          buttonColorScheme={buttonColorScheme}
          getCampaignById={getCampaignById}
          setTableData={setTableData}
          setSelectedRow={setSelectedRow}
          getAllCampaign={getAllCampaign}
        />
      )}
    </Box>
  );
};

export default CampaignTable;
