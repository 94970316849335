import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { IoSend } from "react-icons/io5";
import BotIconBorderSvg from "./BotIconBorerSvg";
export default function InitMainScreen({
  header_color,
  intro_message,
  user_opening_messages,
  toggleIsChatScreen,
  conversation_bot_icon,
  style_params,
}) {
  const subIntro =
    style_params?.sub_heading || "Ask questions and immediate responses";
  const ctaSubHeading =
    style_params?.cta?.sub_heading || "The chatbot respond immedietely";
  const ctaHeading = style_params?.cta?.heading || "Talk with chatbot";
  const iceBreakerHeading =
    style_params?.ice_breaker_heading || "What can I help you with ?";
  return (
    <Stack marginBlock={10} spacing={4}>
      <Flex justifyContent={"center"} alignItems={"center"} flexDir={"column"}>
        {conversation_bot_icon ? (
          <img src={conversation_bot_icon} width={200} />
        ) : (
          <BotIconBorderSvg color={header_color} />
        )}
        <Text fontWeight={"bold"}>{intro_message}</Text>
        <Text>{subIntro}</Text>
      </Flex>
      <GradientBoxByColor color={header_color}>
        <Stack spacing={1}>
          <Text>{iceBreakerHeading}</Text>
          {user_opening_messages.map((m, index) => (
            <Text key={index} color={header_color}>
              {m}
            </Text>
          ))}
        </Stack>
      </GradientBoxByColor>
      <GradientBoxByColor color={header_color}>
        <Flex
          cursor={"pointer"}
          onClick={toggleIsChatScreen}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Box flex={1}>
            <Text>{ctaHeading}</Text>
            <Text color={"gray"}>{ctaSubHeading}</Text>
          </Box>
          <IoSend size={30} color={header_color} />
        </Flex>
      </GradientBoxByColor>
    </Stack>
  );
}
function GradientBoxByColor({ children, color }) {
  return (
    <Box
      fontSize={16}
      p={2}
      maxWidth={400}
      width={"100%"}
      borderRadius={"md"}
      margin={"auto"}
      boxShadow={`0px 2px 29px 0px ${color}`}
    >
      {children}
    </Box>
  );
}
