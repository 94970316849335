import { Avatar, Tag, TagLabel, WrapItem } from "@chakra-ui/react";

export default function WrapProviders({ provider, name, logo, widgetForm }) {
  const { watch, setValue } = widgetForm;

  return (
    <WrapItem>
      <Tag
        key={provider}
        size="lg"
        cursor={"pointer"}
        variant={"subtle"}
        colorScheme={watch("provider") === provider ? "blue" : "gray"}
        p={4}
        onClick={() => setValue("provider", provider)}
      >
        <Avatar src={logo} size="xs" name={name} ml={-1} mr={2} />
        <TagLabel>{name}</TagLabel>
      </Tag>
    </WrapItem>
  );
}
