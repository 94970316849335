import { useCallback, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useWidgetTypeCount() {
  const defaultWidgetTypeCounts = {
    insighto: 0,
    whatsapp: 0,
    twilio: 0,
    telegram: 0,
    leadconnector: 0,
    fb_messenger: 0,
    instagram: 0,
    plivo: 0,
    telnyx: 0,
  };
  const [widgetTypeCounts, setWidgetTypeCounts] = useState(
    defaultWidgetTypeCounts
  );
  const [status, setStatus] = useState("loading");
  const baseUrl = getHostDomain();
  const fetchWidgetCountByType = useCallback(async () => {
    const response = await fetchController(
      baseUrl + `/api/v1/widget/widget_type_count?size=100`
    );
    const widgetCountsByTypes = response.data.reduce(
      (acc, provider) => ({
        ...acc,
        [provider?.type]: provider?.count,
      }),
      {}
    );
    setWidgetTypeCounts((prevWidgetCounts) => ({
      ...prevWidgetCounts,
      ...widgetCountsByTypes,
    }));
    setStatus("idle");
  }, []);
  useEffect(() => {
    fetchWidgetCountByType();
  }, []);

  return { status, widgetTypeCounts };
}
