import {
  Box,
  Center,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";
import Paginator from "../../utils/Loader/Paginator";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";

const DataSourcesList = ({
  isOpen,
  onOpen,
  onClose,
  selectedItem,
  fetch,
  setSelectedDataSource = () => {},
}) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const [paginationSize, setPaginationSize] = useState(10);

  const endpoint = `/api/v1/assistant/${selectedItem?.id}/list_data_sources?page=1&size=50`;
  const DataSourcesEndpoint = `/api/v1/datasource/list?size=${paginationSize}&page=${page}`;

  const urlHit = baseUrl + endpoint;
  const dataSourcesUrl = baseUrl + DataSourcesEndpoint;

  const hitFetch = async () => {
    try {
      setIsLoading(true);

      const getData = await fetchController(urlHit, "GET");
      const getDataSources = await fetchController(dataSourcesUrl, "GET");
      setPages(getDataSources?.data?.pages);
      setSelectedDataSource(getData.data.items);
      if (getData?.data && getDataSources?.data?.items) {
        const linkedItemIds = getData.data.items.map(
          (linkedItem) => linkedItem.id
        );

        const updatedTableData = getDataSources.data.items.map((item) => ({
          ...item,
          linked: linkedItemIds.includes(item.id),
        }));

        const filteredTableData = updatedTableData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const heads =
          getDataSources?.data?.items?.length >= 0
            ? Object.keys(getDataSources.data.items[0])
            : [];
        setHeads(heads);
        setTableData(filteredTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen, searchTerm, page, paginationSize]);

  const handleCheckboxChange = async (item) => {
    const endpoint = `/api/v1/assistant/${selectedItem?.id}/add_datasource/${item?.id}`;
    const urlHit = baseUrl + endpoint;

    try {
      setIsLoading(true);

      // If the checkbox is checked, unlink the data source
      if (item.linked) {
        // Unlink the data source
        const unlinkEndpoint = `/api/v1/datasource/${item?.id}/${selectedItem?.id}/LinkedAssistantDataSource`;
        const unlinkUrl = baseUrl + unlinkEndpoint;
        await fetchController(unlinkUrl, "DELETE");
        showToast("Assistant Unlinked", "success");
      } else {
        // If the checkbox is unchecked, link the data source
        const getData = await fetchController(urlHit, "POST");
        if (getData?.data) {
          showToast("Assistant Linked", "success");
        }
      }
      item.linked = !item.linked;
      hitFetch();
      fetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    hitFetch();
  }, []);

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="70vh" width="95%" maxWidth="600px">
          {isLoading && (
            <Center
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.8)"
              zIndex="999"
            >
              <Spinner />
            </Center>
          )}
          <ModalHeader>Linked Data Sources</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="4">
              <Input
                placeholder="Search by name or description"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            <TableContainer height="50vh" overflowY="Scroll">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Assign</Th>
                    <Th>Name</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData &&
                    tableData.map((item) => {
                      return (
                        <Tr key={item.id}>
                          <Td>
                            <Checkbox
                              mt="10px"
                              ml="15px"
                              isChecked={item.linked}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </Td>
                          <Td>{item.name}</Td>
                          <Td>{item.description}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={2}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={tableData.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DataSourcesList;
