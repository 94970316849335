import {
  FormControl,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import useLlmModels from "../hooks/useLlmModels";

export default function LlmModelSelectForm({ nodeForm }) {
  const { watch } = nodeForm.form;
  const { data = {} } = watch();

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };
  const { llmModelOptions } = useLlmModels();

  return (
    <>
      <FormControl>
        <FormLabel>LLM model</FormLabel>
        <Select
          options={llmModelOptions}
          onChange={({ value }) => {
            nodeForm.form.setValue("data.llm", value);
          }}
          value={llmModelOptions.find(
            (llmModelOption) => llmModelOption.value === data?.llm
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Temperature</FormLabel>
        <Slider
          onChange={(v) => {
            nodeForm.form.setValue("data.temperature", v);
          }}
          min={0}
          max={100}
          aria-label="slider-ex-6"
        >
          <SliderMark value={25} {...labelStyles}>
            0.25
          </SliderMark>
          <SliderMark value={50} {...labelStyles}>
            0.50
          </SliderMark>
          <SliderMark value={75} {...labelStyles}>
            0.75
          </SliderMark>
          <SliderMark
            value={data.temperature}
            textAlign="center"
            bg="blue.500"
            color="white"
            mt="-10"
            ml="-5"
            w="12"
          >
            {(data.temperature || 0) / 100}
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </FormControl>
    </>
  );
}
