import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import { useState } from "react";

export default function useConversationFlow() {
  const baseUrl = getHostDomain();
  const [status, setStatus] = useState("idle")

  const getFlowById = async (id) => {
    try {
      setStatus("loading")
      const response = await fetchController(`${baseUrl}/api/v1/conversation_flow/${id}`, "GET")
      return response;
    } catch (error) {
      console.error(error)
      throw error;
    } finally {
      setStatus("idle")
    }
  }

  const addFlow = async (payload) => {
    try {
      setStatus("loading")
      const response = await fetchController(
        `${baseUrl}/api/v1/conversation_flow`,
        "POST",
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setStatus("idle")
    }
  }

  const editFlow = async (id, payload) => {
    try {
      setStatus("loading");
      const response = await fetchController(
        `${baseUrl}/api/v1/conversation_flow/${id}`,
        "PUT",
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setStatus("idle");
    }
  };

  return {
    status,
    getFlowById,
    addFlow,
    editFlow,
  };
}
