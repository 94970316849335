import { Box, Skeleton, Stack, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import LogDeleteAlert from "./LogDeleteAlert";
import NoLogsFound from "./NoLogsFound";
import ToolFunctionInvokeLogs from "./ToolFunctionInvokeLogs";
import useConversationLogs from "./useConversationLogs";

export default function ConversationLogsPage({ conversationId, skeletonMap }) {
  const selectedLogType = "tool_function_invoke_log";
  const [selectedLog, setSelectedLog] = useState(null);
  const {
    isOpen: isDeleteAlertOpen,
    onClose: closeDeleteAlert,
    onOpen: openDeleteAlert,
  } = useDisclosure();

  const onDeleteLog = useCallback((log) => {
    setSelectedLog(log);
    openDeleteAlert();
  });
  const { response, isLoading, fetchLogs, deleteLog, status } =
    useConversationLogs({
      logType: selectedLogType,
      closeDeleteAlert,
      conversationId
    });

  const logs = response?.data?.items || [];
  
  return (
    <Box>
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} spacing={"2"}>
          <Stack spacing="1">
            {skeletonMap.map((item) => {
              return (
                <>
                  <Skeleton
                    startColor="#3182ce6e"
                    endColor="##3182ce6e"
                    height="36px"
                    bg="green.500"
                    color="white"
                    key={`${item}`}
                  ></Skeleton>
                </>
              );
            })}
          </Stack>
        </Box>
      ) : (
        <>
          {logs.length ? (
            <ToolFunctionInvokeLogs
              logs={logs}
              onOpenCallDetailsModal={null}
              onOpenShowModal={null}
              onDeleteLog={onDeleteLog}
            />
          ) : (
            <NoLogsFound iconSize={50} headingSize="lg" />
          )}
        </>
      )}
      {selectedLog && isDeleteAlertOpen ? (
        <LogDeleteAlert
          isOpen={isDeleteAlertOpen}
          onClose={closeDeleteAlert}
          selectedLog={selectedLog}
          onConfirmDelete={deleteLog}
          status={status}
        />
      ) : null}
    </Box>
  );
}
