import { Box, Button, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import Select from "react-select";
import { getHostDomain } from "../../../../utils/utils";
import { fetchController } from "../../../../utils/FetchController/fetchController";
import { useEffect, useState } from "react";
import { FaPhone } from "react-icons/fa";
import { BsLightningCharge } from "react-icons/bs";
import { TbMessageChatbot } from "react-icons/tb";
import IconWidgetOption from "../../../Playground/IconWidgetOption";

export default function AssistantStep({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
  hasFinished,
  allowedAssistantTypesByWidgets,
}) {
  const baseUrl = getHostDomain();
  const { watch, setValue } = widgetForm;

  const [status, setStatus] = useState("idle");
  const [assistantOptions, setAssistantOptions] = useState([]);

  const getAssistants = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        baseUrl + "/api/v1/assistant/list",
        "GET"
      );

      const options = response?.data?.items
        .filter((item) =>
          allowedAssistantTypesByWidgets[watch("widget_type")].includes(
            item.assistant_type
          )
        )
        .map((assistant) => {
          return {
            value: assistant.id,
            label: assistant.name,
            assistant_type: assistant.assistant_type,
            Icon:
              assistant.assistant_type === "phone"
                ? FaPhone
                : assistant.assistant_type === "realtime_openai"
                ? BsLightningCharge
                : TbMessageChatbot,
          };
        });

      setAssistantOptions(options);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
    getAssistants();
  }, []);

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <Box flex="1" mr="2">
            <Select
              placeholder="Select Assistant"
              isLoading={status === "loading"}
              options={assistantOptions}
              onChange={(selectedOption) => {
                setValue("assistant_id", selectedOption?.value);
                setValue("assistant_type", selectedOption?.assistant_type)
              }}
              components={{ Option: IconWidgetOption }}
            />
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          isDisabled={!watch("assistant_id")}
          isLoading={loadingStatus === "loading"}
          type="submit"
          colorScheme={hasFinished ? "blue" : "yellow"}
        >
          {hasFinished ? "Finish" : "Next"}
        </Button>
      </ModalFooter>
    </form>
  );
}
