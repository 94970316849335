import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { FiPenTool } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { IoMdContact } from "react-icons/io";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { LiaToolsSolid } from "react-icons/lia";
import { MdDataObject } from "react-icons/md";
import ConversationDrawer from "../Contacts/ConversationDrawer";
import LogJsonModal from "./LogJsonModal";

export default function LogRowContactSync(props) {
  const {
    isOpen: isContactDetailsModal,
    onClose: closeContactDetailsModal,
    onOpen: openContactDetailsModal,
  } = useDisclosure();

  const {
    isOpen: isToolResponseModal,
    onClose: closeToolResponseModal,
    onOpen: openToolResponseModal,
  } = useDisclosure();

  const {
    isOpen: isExtractedDataModal,
    onClose: closextractedDataModal,
    onOpen: openExtractedDataModal,
  } = useDisclosure();

  return (
    <>
      <Tr>
        <Td>
          <Tooltip
            label={props.log?.status_code ? props.log?.status_code : "NA"}
          >
            <Box as="span">
              <GoDotFill
                style={{
                  color: props.log?.status_code === 200 ? "green" : "red",
                }}
              />
            </Box>
          </Tooltip>
        </Td>

        <Td>
          <Flex gap={2}>
            <Box as="span">
              {props.log.tool_icon ? (
                <img
                  src={props.log.tool_icon}
                  alt="Logo"
                  height={"16px"}
                  width={"16px"}
                />
              ) : (
                <LiaToolsSolid
                  size={20}
                  style={{
                    margin: "auto",
                  }}
                />
              )}
            </Box>
            <Box
              as="span"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.log.tool_user_name}{" "}
            </Box>
          </Flex>
        </Td>
        <Td>{props.log.assistant_name}</Td>
        <Td
          style={{ cursor: "pointer" }}
          onClick={() => openExtractedDataModal()}
        >
          <div style={{ display: "flex" }}>
            <Button
              leftIcon={<MdDataObject />}
              variant="outline"
              colorScheme="blue"
            >
              {props.log?.extracted_data
                ? Object.keys(props.log?.extracted_data).length
                : 0}
            </Button>
          </div>
        </Td>
        <Td>
          <Tooltip
            label={moment(props.log?.created_at.split("T")[0]).format(
              "MMM Do, YYYY"
            )}
          >
            {props.log?.created_at
              ? moment.utc(props.log?.created_at).fromNow()
              : null}
          </Tooltip>
        </Td>
        <Td>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() => openContactDetailsModal()}
                icon={<IoMdContact />}
              >
                Contact
              </MenuItem>{" "}
              {props.log?.tool_response && (
                <MenuItem
                  onClick={() => openToolResponseModal()}
                  icon={<FiPenTool />}
                >
                  Tool Response
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Td>
      </Tr>
      {isToolResponseModal && props.log?.tool_response && (
        <LogJsonModal
          isOpen={isToolResponseModal}
          onClose={closeToolResponseModal}
          selectedJson={{
            json: { ...JSON.parse(props.log?.tool_response) },
            title: "Tool Response",
          }}
        />
      )}
      {isContactDetailsModal && (
        <ConversationDrawer
          isOpen={isContactDetailsModal}
          onClose={closeContactDetailsModal}
          selectedContact={props.log}
        />
      )}
      {isExtractedDataModal && (
        <LogJsonModal
          isOpen={isExtractedDataModal}
          onClose={closextractedDataModal}
          selectedJson={{
            json: { ...props.log?.extracted_data },
            title: "Extracted Data",
          }}
        />
      )}
    </>
  );
}
