import { Button, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import Instagram from "../../Instagram";

export default function InstagramForm({
  widgetForm,
  onSubmit,
  onBack,
  loadingStatus,
}) {
  const { setValue, watch } = widgetForm;

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <Instagram setValue={setValue} watch={watch} />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
