import React, { useContext } from "react";
import { getWidgetProvider } from "../Widgets/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { Tooltip } from "@chakra-ui/react";

export default function ProviderLogoByWidgetType({ widgetType, size = 25 }) {
  const agency = useContext(AgencyContext);

  const provider = getWidgetProvider({
    type: widgetType,
    logo: agency?.faviconUrl,
    name: agency?.myDetails?.agency_id
      ? agency?.myDetails?.branding?.agency_name
      : "Insighto.AI",
  });  
  return widgetType ? (
    <Tooltip label={provider.label}>
      <img src={provider.logo} width={size} />
    </Tooltip>
  ) : null;
}
