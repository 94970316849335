import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Text,
  Textarea,
  useEditableControls,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FiEdit } from "react-icons/fi";

const BasicDetails = (props) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const { isEditing } = props;

  function EditableControls() {
    const { getEditButtonProps } = useEditableControls();

    return (
      <IconButton
        size="sm"
        variant="ghost"
        icon={<FiEdit />}
        _hover={{ bg: "transparent" }}
        {...getEditButtonProps()}
      />
    );
  }

  return (
    <Box px={4} py={2}>
      <FormControl isInvalid={errors.name} mb="4">
        <FormLabel htmlFor="name" display={"flex"} alignContent="center">
          <Text alignSelf="center">Name</Text>
        </FormLabel>
        <Input
          placeholder="Name"
          {...register("name", {
            required: "Name is required",
          })}
        />
        {errors.name && (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.description} mb="4">
        <FormLabel htmlFor="description" display={"flex"} alignContent="center">
          <Text alignSelf="center">Description</Text>
        </FormLabel>
        <Textarea
          placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
          {...register("description", {
            required: "Description is required",
          })}
          resize="vertical"
        />
        {errors.description && (
          <FormErrorMessage>{errors.description.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default BasicDetails;
