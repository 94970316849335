import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { toast } from "react-toastify";

export default function useCampaign() {
  const agency = useContext(AgencyContext);
  const baseUrl = getHostDomain();

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);

  const [status, setStatus] = useState("pending");
  const [campaignList, setCampaignList] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [tableData, setTableData] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [existingTotalCampaigns, setExistingTotalCampaigns] = useState(0);

  const { textColor, buttonColorScheme } = agency;
  const {
    isOpen: isAddEditCampaignOpen,
    onOpen: onAddEditCampaignOpen,
    onClose: onAddEditCampaignClose,
  } = useDisclosure();

  const campaignDependency = [paginationSize, page];

  const getAllCampaign = useCallback(async () => {
    try {
      const endpoint = `/api/v1/campaign/list?size=${paginationSize}&page=${page}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      const data = response.data.items.map((item) => {
        const newItem = {
          ...item,
          isChecked: false,
          contacts: item.contacts.filter((contact) => contact !== null),
        };
        return newItem;
      });
      setExistingTotalCampaigns(response.data.total);
      setCampaignList(data);
      setTableData(data);
      setPages(response.data.pages);
      setStatus("success");
    } catch (e) {
      console.log(e);
      setStatus("error");
    }
  }, campaignDependency);

  const updateCampaignById = useCallback(async (row) => {
    try {
      const endpoint = `/api/v1/campaign/update/${row.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "PUT", row);
      toast.success(`${response.message} !`);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const deleteCampaignById = useCallback(async (row) => {
    try {
      const endpoint = `/api/v1/campaign/${row.id}`;
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "DELETE");
      getAllCampaign();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getCampaignById = useCallback(async (id) => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/campaign/get/${id}`,
        "GET"
      );
      const campaign = {
        ...response.data,
        contacts: response.data.contacts.filter((contact) => contact !== null),
      };
      return campaign;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleMultipleDelete = useCallback(async () => {
    try {
      tableData.forEach((row) => {
        if (row.isChecked) {
          deleteCampaignById(row);
        }
      });
      toast.success("Deleted selected campaigns !");
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    setSelectedCampaigns([]);
    getAllCampaign();
  }, campaignDependency);

  return {
    status,
    page,
    pages,
    paginationSize,
    campaignList,
    selectedRow,
    textColor,
    buttonColorScheme,
    isAddEditCampaignOpen,
    tableData,
    selectedCampaigns,
    existingTotalCampaigns,
    setSelectedCampaigns,
    setTableData,
    setPage,
    setPages,
    setPaginationSize,
    setSelectedRow,
    onAddEditCampaignOpen,
    onAddEditCampaignClose,
    deleteCampaignById,
    getAllCampaign,
    updateCampaignById,
    getCampaignById,
    handleMultipleDelete,
  };
}
