import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Tooltip,
} from "@chakra-ui/react";

export default function CommonWidgetTypeForm({ widgetForm }) {
  const { register, setValue, formState, watch } = widgetForm;
  const { errors } = formState;

  return (
    <Stack spacing={2}>
      <Box display="inline-block">
        <Tooltip label="Recording done on Twilio" aria-label="tooltip">
          <Checkbox
            onChange={(e) => {
              setValue("recording", e.currentTarget.checked);
            }}
            isChecked={watch("recording")}
          >
            Recording
          </Checkbox>
        </Tooltip>
      </Box>
      <FormControl>
        <FormLabel>Max Call Duration (seconds)</FormLabel>
        <Input
          placeholder="Max Call Duration (seconds)"
          type="number"
          {...register("max_call_duration", {
            valueAsNumber: true,
            required: true,
          })}
        />
        <FormErrorMessage>
          {errors.max_call_duration &&
          errors.max_call_duration.type === "required"
            ? "Field required"
            : errors.max_call_duration &&
              errors.max_call_duration.type === "valueAsNumber"
            ? "Value should be number"
            : null}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
}
