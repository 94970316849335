import {
  Button,
  Flex,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import CommonWidgetTypeForm from "./CommonWidgetTypeForm";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Telnyx from "../../Telnyx";

export default function TelnyxForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { setValue, watch } = widgetForm;

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <CommonWidgetTypeForm widgetForm={widgetForm} />
          <Telnyx setValue={setValue} watch={watch} />

          <Flex justifyContent={"flex-start"}>
            <Link
              color={"blue.500"}
              href={
                "https://support.telnyx.com/en/articles/4305158-api-keys-and-how-to-use-them"
              }
              isExternal
            >
              Get Keys <ExternalLinkIcon mx="2px" />
            </Link>
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
