import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { IoInformationCircleOutline } from "react-icons/io5";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CustomCheckbox from "./CustomCheckbox";

const AllowHumanHandover = ({ member, toolTipMsg }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  const toolTipMessage = {
    has_human_agent:
      "Shows an icon on web widgets for user to connect.  Human Agents can chat in real-time with them from Human Agents section",
  };

  return (
    <Box px={4}>
      {watch("assistant_type") === "phone" && (
        <Box>
          <Checkbox
            mt="10px"
            {...register("attributes.detect_voicemail")}
            isChecked={watch("attributes.detect_voicemail")}
            colorScheme={buttonColorScheme}
          >
            <Text>
              Detect if answered by voicemail, fax, or answering machine, and
              disconnect (works only with Twilio for outbound calls)
            </Text>
          </Checkbox>
        </Box>
      )}
      {watch("assistant_type") === "simple" && (
        <Flex gap={2} justifyContent="flex-start" alignItems="center">
          <CustomCheckbox
            disabled={!member}
            name="has_human_agent"
            onChange={(e) =>
              setValue("has_human_agent", e.currentTarget.checked)
            }
            isChecked={watch("has_human_agent")}
          />
          <FormLabel
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
            mb="0"
          >
            <span>Allow Human Handover</span>
            <Tooltip
              aria-label="Info"
              hasArrow
              label={member ? toolTipMessage.has_human_agent : toolTipMsg}
            >
              <span>
                <IoInformationCircleOutline size={15} />
              </span>
            </Tooltip>
          </FormLabel>
        </Flex>
      )}
    </Box>
  );
};

export default AllowHumanHandover;
