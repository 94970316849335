import { Box } from "@chakra-ui/react";
import React from "react";

const CustomPieChart = ({ data, size = 200 }) => {
  const total = data.reduce((sum, { value }) => sum + value, 0);
  const radius = size / 2;
  const center = size / 2;
  let startAngle = 0;

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
      `M ${start.x} ${start.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
      "L",
      x,
      y,
      "Z",
    ].join(" ");
  };

  return (
    <Box margin={"auto"}>
      <svg width={size} height={size}>
        {data.length === 0 ? (
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill="lightgray"
            stroke="#fff"
            strokeWidth="2"
          >
            <title>No Data Available</title>
          </circle>
        ) : data.length === 1 ? (
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill={data[0].color}
            stroke="#fff"
            strokeWidth="2"
          >
            <title>
              {data[0].label}: {data[0].value}
            </title>
          </circle>
        ) : (
          data.map(({ label, value, color }, index) => {
            const angle = (value / total) * 360;
            const endAngle = startAngle + angle;
            const path = describeArc(
              center,
              center,
              radius,
              startAngle,
              endAngle
            );

            startAngle = endAngle;

            return (
              <path
                key={index}
                d={path}
                fill={color}
                stroke="#fff"
                strokeWidth="2"
              >
                <title>
                  {label}: {value}
                </title>
              </path>
            );
          })
        )}
      </svg>
    </Box>
  );
};

export default CustomPieChart;
