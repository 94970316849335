import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import LlmModelSelectForm from "./LlmModelSelectForm";
import ResponseTypeSelectLlmNode from "./ResponseTypeSelectLlmNode";
import DataSourceForm from "./DataSourceForm";

export default function BasicLlmForm({ onClose, nodeForm, onSubmit, onBack }) {
  const { register, watch } = nodeForm.form;

  return (
    <>
      <form onSubmit={nodeForm.form.handleSubmit(onSubmit)}>
        <ModalBody>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register("data.name")} />
            </FormControl>
            <ResponseTypeSelectLlmNode nodeForm={nodeForm} />

            {watch("data.response.type") === "llm" && (
              <>
                <LlmModelSelectForm nodeForm={nodeForm} />
                <DataSourceForm nodeForm={nodeForm} />
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onBack}>
            Back
          </Button>
          <Button type="submit" colorScheme="yellow">
            Next
          </Button>
        </ModalFooter>
      </form>
    </>
  );
}
