import { useCallback, useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function useUserUsages() {
  const [userUsages, setUserUsages] = useState([]);
  const [status, setStatus] = useState("loading");
  const baseUrl = getHostDomain();
  const fetchUserUsage = useCallback(async () => {
    const response = await fetchController(
      baseUrl + `/api/v1/user_usage/user_daily_usages?size=100`
    );
    setUserUsages(response.data?.items);
    setStatus("idle");
  }, []);
  useEffect(() => {
    fetchUserUsage();
  }, []);
  const labels = userUsages.map((item) => item?.usage_date);
  const callDurations = userUsages.map(
    (item) => (item?.aggregation?.call_duration || 0) / 60
  );
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  const graphConfig = {
    data: {
      labels,
      datasets: [
        {
          label: "Call duration(mins)",
          data: callDurations,
          backgroundColor: textColor,
          barPercentage: 0.1,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const value = context.raw;
              const minutes = Math.floor(value);
              const seconds = Math.round((value % 1) * 60);
              return `${minutes} min ${seconds} sec`;
            },
          },
        },
      },
    },
  };
  return { status, userUsages, graphConfig };
}
