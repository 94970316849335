import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

const ViewMoreTextModal = (props) => {
  const { isMessageModalOpen, closeMessageModal, title, text } = props;
  return (
    <Modal size={"xl"} isOpen={isMessageModalOpen} onClose={closeMessageModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color={"#3182ce"}>{title}</Text>
        </ModalHeader>
        <ModalCloseButton alignItems="center" />
        <ModalBody>
          <Box>
            <Text>{text}</Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeMessageModal} variant="outline" width="104px">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewMoreTextModal;
