import { Card, CardBody, Text } from "@chakra-ui/react";
import { Handle } from "@xyflow/react";

export default function CustomNode({ data }) {
  const isStartNode = data?.node_type === "start";

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "3px",
        minWidth: isStartNode ? "100px" : "150px",
        maxWidth: "300px",
        backgroundColor: data?.backgroundColor || "#fff",
        color: data?.textColor || "inherit",
        fontSize: "12px",
        border: "1px solid #1a192b",
        textAlign: "center",
        position: "relative",
        cursor: isStartNode ? "grab" : "pointer"
      }}
    >
      {data.label}
      {data?.response?.value && (
        <Card mt={2} variant={"filled"}>
          <CardBody>
            <Text fontSize={"0.5rem"} textAlign={"left"} noOfLines={5}>
              {data.response.value}
            </Text>
          </CardBody>
        </Card>
      )}

      {/* Input Handle */}
      {!isStartNode && (
        <Handle
          type="target" // This defines it as an input
          position="top" // Adjust position as needed
          style={{ background: "#555" }}
        />
      )}

      {/* Output Handle */}
      <Handle
        type="source" // This defines it as an output
        position="bottom" // Adjust position as needed
        style={{ background: "#555" }}
      />
    </div>
  );
};
