import React, { useRef, useState, useEffect, useContext } from "react";
import Layout from "../Layout/Layout";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { toast } from "react-toastify";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import AssistantType from "./AssistantType";
import BasicDetails from "./BasicDetails";
import Prompt from "./Prompt";
import ConnectTools from "./ConnectTools";
import LLMrelated from "./LLMrelated";
import DataSourcesField from "./DataSourcesField";
import VoicesField from "./VoicesField";
import ConversationFields from "./ConversationFields";
import FormFields from "./FormFields";
import WebhookAndIntentFields from "./WebhookAndIntentFields";
import {
  IoDocumentTextOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { BiConversation, BiMessageSquareDetail } from "react-icons/bi";
import { AiFillAudio } from "react-icons/ai";
import { TiChartBarOutline } from "react-icons/ti";
import { PiBookOpenTextLight } from "react-icons/pi";
import { MdOutlineSettingsVoice } from "react-icons/md";
import { VscTools } from "react-icons/vsc";
import { HiOutlineCpuChip } from "react-icons/hi2";
import AllowHumanHandover from "./AllowHumanHandover";

const AddEditAssistant_new = () => {
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, myDetails } = agency;
  const assistantFormMethod = useForm({
    defaultValues: {
      assistant_type: "simple",
      templatePhonePrompt: undefined,
      voice: true,
      hide_ds: false,
      has_human_agent: false,
      use_tools: false,
      voiceLanguages: [],
      llmModel: null,
      systemPrompt: `As an AI assistant built by a team of engineers at ${
        myDetails?.branding?.agency_name || "Algoscale"
      }, please answer the user query with the context provided.`,
      attributes: {
        conversation: false,
        contact: false,
        first_sentence: "",
        interruption_time: 10,
        conversation_flow_id: null,
      },
    },
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = assistantFormMethod;

  const baseUrl = getHostDomain();
  const { assistantId } = useParams();
  const navigate = useNavigate();

  const [myownVoiceOptions, setMyownVoiceOptions] = useState([]);
  const [editData, setEditData] = useState(null);
  const [member, setMember] = useState(false);
  const { state } = useContext(SettingContext);

  const [status, setStatus] = useState("idle");
  const [linkAssistantTools, setLinkAssistantTools] = useState([]);
  const loading = status === "loading";

  const [customFieldTags, setCustomFieldTags] = useState([]);

  const resetForm = () =>
    reset({
      assistant_type: "simple",
      voice: true,
      llmModel: null,
      systemPrompt: "",
      description: "",
      name: "",
      has_human_agent: false,
      use_tools: watch("use_tools"),
      voiceLanguages: voiceSsts
        .filter((item) => item.locale_code === "en")
        .map((language) => ({
          label: language.name,
          value: language.locale_code,
          id: language.id,
          language_group: language.language_group,
        })),
      attributes: {
        conversation: false,
        contact: false,
        conversation_flow_id: null,
      },
    });

  const onSubmitAssistantPhone = async (data, exit = true) => {
    try {
      setStatus("loading");
      if (
        !data.custom_voice &&
        data.voiceLanguages.some((lang) => !lang.speaker)
      ) {
        toast.error("Please attach a speaker to the language");
        return;
      }

      await fetchController(
        baseUrl +
          (data.id ? `/api/v1/assistant/${data.id}` : "/api/v1/assistant"),
        data.id ? "PUT" : "POST",
        {
          assistant_type: data.assistant_type || "simple",
          llm_model: data.llmModel?.value || "gpt-3.5-turbo-1106",
          name: data.name,
          voice: data.voice,
          system_prompt: data.systemPrompt,
          description: data.description || "string",
          use_tools: watch("use_tools"),
          voice_languages:
            data.voiceLanguages?.map((lang) => ({
              stt: lang.id,
              tts: lang.speaker.value,
            })) || [],
          custom_voice: data.custom_voice,
          attributes: data.attributes || {},
          has_human_agent: data?.has_human_agent || false,
          hide_ds: data?.hide_ds || false,
          show_images: data.showImages || false,
        }
      );
      toast.success(`Assistant ${data.id ? "updated" : "created"}`);
      !data.id && resetForm();
      if (exit) navigate("/ai-agents/assistants");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };

  const onSubmit = async (data, exit = true) => {
    try {
      setStatus("loading");

      const endpoint = data.id
        ? `/api/v1/assistant/${data.id}`
        : "/api/v1/assistant";
      const urlHit = baseUrl + endpoint;

      if (data.voice) {
        if (data.voiceLanguages.some((lang) => !lang.speaker)) {
          toast.error("Please attach a speaker to the language");
          return;
        }
      }

      const attributes = data.attributes || {};
      if (attributes?.detect_voicemail) {
        attributes["detect_voicemail"] = attributes.detect_voicemail
          ? "hangup"
          : null;
      }
      const body = {
        assistant_type: data.assistant_type || "simple",
        llm_model: data.llmModel?.value,
        name: data.name || "string",
        description: data.description || "string",
        use_tools: watch("use_tools"),
        attributes,
        system_prompt: data.systemPrompt,
        voice: data.voice,
        voice_languages:
          data.voiceLanguages?.map((lang) => ({
            stt: lang.id,
            tts: lang.speaker && lang.speaker.value,
          })) || [],
        custom_voice: data.custom_voice,
        show_images: data.showImages,
        hide_ds: data.hide_ds || false,
        has_human_agent: data.has_human_agent,
      };
      await fetchController(urlHit, data.id ? "PUT" : "POST", body);
      toast.success(
        data.id ? "Assistant Updated" : "Assistant Added",
        "success"
      );
      !data.id && resetForm();
      if (exit) navigate("/ai-agents/assistants");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };

  const loadOwnVoice = async () => {
    const endpoint = `/api/v1/voice/list`;
    const urlHit = baseUrl + endpoint;
    const getData = await fetchController(urlHit, "GET");

    const modifiedData = getData?.data?.items?.map((val, index) => {
      return {
        value: val.id,
        label: val.name,
      };
    });

    setMyownVoiceOptions(modifiedData);
  };
  useEffect(() => {
    loadOwnVoice();
  }, []);

  const [voiceSsts, setVoiceSsts] = useState([]);
  const [voices, setVoices] = useState([]);
  const languages = voiceSsts.map((language) => ({
    label: language.name,
    value: language.locale_code,
    id: language.id,
    language_group: language.language_group,
    voicestt_provider: language.voicestt_provider,
  }));

  const getAssistantTool = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/assistant/${assistantId}/LinkAssistantTool`,
        "GET"
      );
      setLinkAssistantTools(response?.data?.items || []);
      setValue("use_tools", response?.data?.items?.length > 0);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      setStatus("loadingAssitant");

      const response = await fetchController(
        baseUrl + "/api/v1/voice/list/voicestt?size=200"
      );
      const { data: voicesData } = await fetchController(
        baseUrl + "/api/v1/voice/list/voicetts?size=200"
      );
      setVoices(voicesData.items);
      setVoiceSsts(response.data.items);
      setMember(state?.credits?.human_agents > 0 ? true : false);
      if (assistantId) {
        try {
          const { data } = await fetchController(
            baseUrl + `/api/v1/assistant/${assistantId}`
          );
          setEditData(data);
          await getAssistantTool();

          setValue("assistant_type", data.assistant_type);
          setValue("custom_voice", data.custom_voice);
          setValue("name", data.name);
          setValue("hide_ds", data.hide_ds || false);
          setValue("has_human_agent", data.has_human_agent || false);

          setValue("description", data.description);
          setValue("systemPrompt", data.system_prompt);

          setValue("voice", data.voice);
          const selectedStts = data.voice_languages.map(
            (voice_lang) => voice_lang.stt
          );

          const selectedVoiceLanguages = response.data.items
            .filter((lang) => selectedStts.includes(lang.id))
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
              voicestt_provider: language.voicestt_provider,
            }));

          const voiceLanguages = selectedVoiceLanguages.map(
            (selectedVoiceLang, index) => {
              const speakerId = data.voice_languages[index].tts;
              const voicePerson = voicesData.items.find(
                (voice) => speakerId === voice.id
              );
              return {
                ...selectedVoiceLang,
                speaker: voicePerson && {
                  label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                  value: speakerId,
                  voicetts_provider: voicePerson.voicetts_provider,
                },
              };
            }
          );

          setValue("voiceLanguages", voiceLanguages);
          setValue("showImages", data.show_images);
          setValue("id", data.id);
          setValue("assistant_type", data.assistant_type);

          setValue("attributes", data.attributes);
        } catch (error) {
          resetForm();
        } finally {
          setStatus("idle");
        }
      } else {
        resetForm();

        setValue(
          "voiceLanguages",
          response.data.items
            .filter((item) => item.locale_code === "en")
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
              voicestt_provider: language.voicestt_provider,
            }))
        );
      }
      setStatus("idle");
    })();
  }, [assistantId]);

  const loadingAssitant = status === "loadingAssitant";
  // const form = useRef();

  const handlePromptSave = () => {
    const text = getValues("systemPrompt");
    const systemPromptText = text.replace(/\s\/$/, "");
    setValue("systemPrompt", systemPromptText);
    return systemPromptText;
  };

  const onSaveAndExit = async () => {
    const submit =
      watch("assistant_type") === "simple"
        ? handleSubmit(onSubmit)
        : handleSubmit(onSubmitAssistantPhone);
    handlePromptSave();
    submit();
  };

  const extractCustomFieldKeywords = (text) => {
    const regex = /\{{(.*?)\}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  useEffect(() => {
    const keywords = extractCustomFieldKeywords(watch("systemPrompt"));
    setCustomFieldTags(keywords);
  }, [watch("systemPrompt")]);

  const toolTipMsg =
    myDetails && myDetails?.agency_id
      ? `Please contact ${myDetails?.branding?.agency_name || ""} `
      : "Please upgrade to Platinum or above";

  const assistantTabList = {
    Model: [
      {
        title: "LLM Related",
        icon: HiOutlineCpuChip,
        component: <LLMrelated editData={editData} />,
      },
      { title: "Prompt", icon: BiMessageSquareDetail, component: <Prompt /> },
    ],
    ["Voice (In & Out)"]: [
      {
        title: " Voice",
        icon: MdOutlineSettingsVoice,
        component: (
          <VoicesField
            myownVoiceOptions={myownVoiceOptions}
            languages={languages}
            voices={voices}
          />
        ),
      },
    ],
    Tools: [
      {
        title: "Tools",
        icon: VscTools,
        component: (
          <ConnectTools
            assistantId={assistantId}
            getAssistantTool={getAssistantTool}
            linkAssistantTools={linkAssistantTools}
          />
        ),
      },
    ],
    ["Data Sources"]: [
      {
        title: "Data Sources",
        icon: TiChartBarOutline,
        component: (
          <DataSourcesField member={member} assistantId={assistantId} />
        ),
      },
    ],
    Advanced: [
      {
        title: "AllowHumanHandover",
        component: (
          <AllowHumanHandover member={member} toolTipMsg={toolTipMsg} />
        ),
      },
      {
        title: "Form",
        icon: IoDocumentTextOutline,
        component: (
          <FormFields assistantId={assistantId} linkAssistantForms={[]} />
        ),
      },
      {
        title: "Conversation",
        icon: BiConversation,
        component: <ConversationFields member={member} />,
      },
      {
        title: "Webhook & intent",
        icon: PiBookOpenTextLight,
        component: (
          <WebhookAndIntentFields assistantId={assistantId} fetch={null} />
        ),
      },
    ],
  };

  const [openIndexes, setOpenIndexes] = useState([0]);

  return (
    <div>
      <Layout>
        <Box
          border="1px"
          borderRadius="md"
          h="90svh"
          padding={4}
          overflowY={"auto"}
        >
          <Box width="100%">
            <FormProvider {...assistantFormMethod}>
              <form
                onSubmit={handleSubmit((data) => {
                  const promptText = handlePromptSave();
                  data.systemPrompt = promptText;
                  if (data.assistant_type === "phone") {
                    onSubmitAssistantPhone(data, false);
                  } else {
                    onSubmit(data, false);
                  }
                })}
              >
                {loadingAssitant ? (
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Spinner />
                  </Flex>
                ) : (
                  <>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      py={4}
                    >
                      <Text fontSize="xl" fontWeight="bold" color={textColor}>
                        {watch("id") ? "Update Assistant" : "Add an Assistant"}
                      </Text>
                      {!loadingAssitant && (
                        <Stack direction={"row"} gap={3}>
                          <Button
                            type="button"
                            colorScheme={buttonColorScheme}
                            isLoading={loading}
                            onClick={onSaveAndExit}
                          >
                            Save & Exit
                          </Button>
                          {watch("id") ? (
                            <Button
                              isLoading={loading}
                              colorScheme={buttonColorScheme}
                              type="submit"
                            >
                              Save
                            </Button>
                          ) : null}
                        </Stack>
                      )}
                    </Flex>
                    <BasicDetails isEditing={watch("id") ? true : false} />
                    <AssistantType voiceSsts={voiceSsts} voices={voices} />
                    <Tabs size="md">
                      <TabList>
                        {Object.keys(assistantTabList)
                          .filter(
                            (assistantTab) =>
                              !(
                                watch("assistant_type") === "realtime_openai" &&
                                assistantTab === "Data Sources"
                              )
                          )
                          .map((key) => {
                            return <Tab key={key}>{key}</Tab>;
                          })}
                      </TabList>
                      <TabPanels>
                        {Object.entries(assistantTabList)
                          .filter(
                            ([key, value]) =>
                              !(
                                watch("assistant_type") === "realtime_openai" &&
                                key === "Data Sources"
                              )
                          )
                          .map(([key, value]) => {
                            return (
                              <TabPanel key={key}>
                                {value.map((tabPanel) => tabPanel.component)}
                              </TabPanel>
                            );
                          })}
                      </TabPanels>
                    </Tabs>
                  </>
                )}
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Layout>
    </div>
  );
};

export default AddEditAssistant_new;
