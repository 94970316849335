import {
  Button,
  Flex,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import CommonWidgetTypeForm from "./CommonWidgetTypeForm";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Plivo from "../../Plivo";

export default function PlivoForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { setValue, watch } = widgetForm;

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <CommonWidgetTypeForm widgetForm={widgetForm} />
          <Plivo watch={watch} setValue={setValue} />

          <Flex justifyContent={"flex-start"}>
            <Link
              color={"blue.500"}
              href={
                "https://www.twilio.com/en-us/blog/better-twilio-authentication-csharp-twilio-api-keys"
              }
              isExternal
            >
              Get Keys <ExternalLinkIcon mx="2px" />
            </Link>
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
