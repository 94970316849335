import { Button, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import LeadConnectorFields from "../../LeadConnectorFields";
import useExternalAuth from "../../../Tools/useExternalAuth";

export default function LeadConnectorForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { watch, setValue } = widgetForm;

  const { auths: leadConnectorAuths } = useExternalAuth({
    auth_provider: "leadconnector",
  });

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <LeadConnectorFields
            leadConnectorAuths={leadConnectorAuths}
            watch={watch}
            setValue={setValue}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
