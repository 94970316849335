import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Td,
} from "@chakra-ui/react";
import { AiOutlineEdit, AiOutlineLogin } from "react-icons/ai";
import { FaCode, FaDatabase } from "react-icons/fa";
import { FaFileWaveform } from "react-icons/fa6";
import { IoMdCloudUpload } from "react-icons/io";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { MdContentCopy, MdDelete, MdOutlineDashboardCustomize, MdWebhook } from "react-icons/md";
import { RiRobot2Line } from "react-icons/ri";
import { TbTargetArrow } from "react-icons/tb";
import { TfiFiles } from "react-icons/tfi";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { MdLockReset } from "react-icons/md";
import { HiOutlineDuplicate } from "react-icons/hi";


export default function RenderOperationTableColumn(props) {
  return (
    <>
      {props.tableName === "data-sources" && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() => props.handleSourceListModal(props.row.original)}
                icon={<RiRobot2Line color="blue" />}
              >
                Assistants
              </MenuItem>
              <MenuItem
                onClick={() => props.handleListModal(props.row.original)}
                icon={<TfiFiles color="blue" />}
              >
                Files
              </MenuItem>
              <MenuItem
                onClick={() => props.handleUploadClick(props.row.original)}
                icon={<IoMdCloudUpload color="blue" />}
              >
                Upload
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.setValue(props.row.original.id);
                  props.onCopy();
                }}
                icon={<MdContentCopy color="blue" />}
              >
                {props.hasCopied ? "Copied!" : "Copy Datasource ID"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (!props.row.original.assistants_count) {
                    props.handleDelete();
                  }
                }}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete
                      color={
                        props.row.original.assistants_count > 0
                          ? "lightgray"
                          : "red"
                      }
                    />
                  )
                }
                isDisabled={props.row.original.assistants_count > 0}
              >
                {props.row.original.assistants_count > 0
                  ? "Used by Assistant"
                  : "Delete"}
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
      {props.tableName === "assistant" && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              {(props.row.original.assistant_type === "phone" ||
                props.row.original.assistant_type === "simple") && (
                <MenuItem
                  onClick={() => props.handleAssistantModal(props.row.original)}
                  icon={<FaDatabase color="blue" />}
                >
                  View Data Sources
                </MenuItem>
              )}
              <MenuItem
                onClick={() => props.handleEditAssistant()}
                icon={<AiOutlineEdit />}
              >
                Edit Assistant
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.setValue(props.row.original.id);
                  props.onCopy();
                }}
                icon={<MdContentCopy color="blue" />}
              >
                {props.hasCopied ? "Copied!" : "Copy Assistant ID"}
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDeleteAssistant()}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete color="red" />
                  )
                }
              >
                Delete Assistant
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
      {props.tableName === "widget" && (
        <>
          <Td textAlign={"center"}>
            <Menu>
              <MenuButton
                colorScheme="blue"
                size={"sm"}
                as={IconButton}
                aria-label="Options"
                icon={<IoEllipsisVerticalSharp />}
                variant="outline"
              />
              <MenuList>
                <MenuItem
                  onClick={() => props.handleGetCode()}
                  icon={<FaCode color="blue" />}
                >
                  Get Code & QR
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.setValue(props.row.original.id);
                    props.onCopy();
                  }}
                  icon={<MdContentCopy color="blue" />}
                >
                  {props.hasCopied ? "Copied!" : "Copy Widget ID"}
                </MenuItem>
                <MenuItem
                  onClick={props.handleWidgetEdit}
                  icon={<AiOutlineEdit color="blue" />}
                >
                  Edit Widget
                </MenuItem>

                {["phone", "whatsapp"].includes(
                  props.row.original.widget_type
                ) ? null : (
                  <MenuItem
                    onClick={() => {
                      const type = ["html_call", "web_call"].includes(
                        props.row.original.widget_type
                      )
                        ? props.row.original?.widget_type
                        : "chat";
                      props.navigate(
                        `/widgets/${props.row.original.id}/${type}/customize`
                      );
                    }}
                    icon={<MdOutlineDashboardCustomize color="blue" />}
                  >
                    Customize Widget
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => props.handleWidgetDelete()}
                  icon={
                    props.loadingRow === props.row.original.id ? (
                      <Spinner size="sm" />
                    ) : (
                      <MdDelete color="red" />
                    )
                  }
                >
                  Delete Widget
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </>
      )}
      {[
        "agencyBillingPlan_regular",
        "agencyBillingPlan_addons",
        "agencyBillingPlan_hidden",
      ].includes(props.tableName) && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() => props.handleEditBillingPlan()}
                icon={<AiOutlineEdit />}
              >
                Update Billing Plan
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDeleteBillingPlan()}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete color="red" />
                  )
                }
              >
                Delete Billing Plan
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
      {props.tableName === "subaccount" && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  props.handleClickOnSubAccLogin(props.row.original)
                }
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <AiOutlineLogin />
                  )
                }
              >
                Login
              </MenuItem>
              <MenuItem
                onClick={() =>
                  props.handleClickOnSubAccActivate(props.row.original)
                }
                icon={<VscActivateBreakpoints />}
              >
                Activate
              </MenuItem>
              <MenuItem
                onClick={() => props.handleOpenResetPasswordModal()}
                icon={<MdLockReset />}
              >
                Reset password
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDeleteSubaccount()}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete color="red" />
                  )
                }
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
      {props.tableName === "prompt" && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() => props.handleEditPrompt()}
                icon={<AiOutlineEdit />}
              >
                Edit Prompt
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDeletePrompt()}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete color="red" />
                  )
                }
              >
                Delete Prompt
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
      {props.tableName === "conversationFlow" && (
        <Td textAlign={"center"}>
          <Menu>
            <MenuButton
              colorScheme="blue"
              size={"sm"}
              as={IconButton}
              aria-label="Options"
              icon={<IoEllipsisVerticalSharp />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() => props.handleEditFlow()}
                icon={<AiOutlineEdit />}
              >
                Edit Flow
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDeleteFlow()}
                icon={
                  props.loadingRow === props.row.original.id ? (
                    <Spinner size="sm" />
                  ) : (
                    <MdDelete color="red" />
                  )
                }
              >
                Delete Flow
              </MenuItem>
              <MenuItem
                onClick={() => props.handleDuplicateFlow()}
                icon={<HiOutlineDuplicate />}
              >
                Duplicate Flow
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </>
  );
}
