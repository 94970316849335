import {
  Box,
  Button,
  useDisclosure,
  Flex,
  Tooltip,
  Text,
  HStack,
  Tag,
  TagLabel,
  Divider,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import LinkFormAssistantModal from "../Modals/LinkFormAssistantModal";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useFormContext } from "react-hook-form";

const FormFields = ({ assistantId = null, linkAssistantForms = [] }) => {
  const baseUrl = getHostDomain();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [linkedItem, setLinkedItem] = useState([]);
  const [paginationSize, setPaginationSize] = useState(10);

  const { watch } = useFormContext();

  const {
    isOpen: isFormOpen,
    onOpen: onOpenForm,
    onClose: onFormClose,
  } = useDisclosure();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  const assistantFormListEndpoint = `/api/v1/assistant/${assistantId}/LinkAssistantForm?page=1&size=50`;
  const assistantFormUrl = baseUrl + assistantFormListEndpoint;
  const DataSourcesEndpoint = `/api/v1/form/list?page=${page}&size=${paginationSize}`;
  const dataSourcesUrl = baseUrl + DataSourcesEndpoint;

  const AllDataSourcesEndpoint = `/api/v1/form/list`;
  const allDataSourcesUrl = baseUrl + AllDataSourcesEndpoint;

  const loadForm = async () => {
    try {
      setIsLoading(true);

      const getDataSources = await fetchController(dataSourcesUrl, "GET");
      const allGetDataSources = await fetchController(allDataSourcesUrl, "GET");
      const getData = await fetchController(assistantFormUrl, "GET");

      if (getData?.data && getDataSources?.data?.items) {
        const linkedItemIds = getData.data.items.map(
          (linkedItem) => linkedItem.form_id
        );
        const selectedForms = allGetDataSources.data.items.filter((item) => {
          return linkedItemIds.includes(item.id);
        });

        const updatedTableData = getDataSources.data.items.map((item) => ({
          ...item,
          linked: linkedItemIds.includes(item.id),
        }));

        const filteredTableData = updatedTableData.filter((item) => {
          return (
            item.trigger_instructions
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.form_type.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
        const heads =
          getDataSources?.data?.items?.length >= 0
            ? Object.keys(getDataSources.data.items[0])
            : [];
        setPages(getDataSources?.data?.pages);
        setHeads(heads);
        setLinkedItem(selectedForms);

        setTableData(filteredTableData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadForm();
  }, [isFormOpen, searchTerm, page, paginationSize]);

  const toolTipMessage =
    watch("assistant_type") === "simple"
      ? "Lets you render Simple Forms to users as per the trigger instructions or use Natural Forms to extract data from the Conversation."
      : "Lets you extract data from the Conversation once the call is ended.";

  return (
    <Box px={4} py={2}>
      <Text fontWeight="bold">Form</Text>
      <Flex justifyContent={"space-between"} mt={1}>
        <Tooltip
          aria-label="Connected Form"
          placement="right"
          label={toolTipMessage}
        >
          <Box>
            <Button
              size="xs"
              type="button"
              isDisabled={!assistantId}
              colorScheme={buttonColorScheme}
              onClick={() => {
                onOpenForm();
              }}
            >
              Assign Form
            </Button>
          </Box>
        </Tooltip>
        <Box>
          <Text as={"b"}>{"Connected Forms:"}</Text>{" "}
          <Text as={"b"}>{linkedItem.length}</Text>
        </Box>
      </Flex>
      <HStack spacing={4} mt="4">
        {linkedItem.map((item) => (
          <Tag size={"sm"} key={item.id} variant="subtle">
            <TagLabel>{item.name}</TagLabel>
          </Tag>
        ))}
      </HStack>
      {isFormOpen ? (
        <LinkFormAssistantModal
          isOpen={isFormOpen}
          onConfirm={onOpenForm}
          onClose={onFormClose}
          selectedItem={{ id: assistantId }}
          tableData={tableData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          page={page}
          setPage={setPage}
          pages={pages}
          setSearchTerm={setSearchTerm}
          loadForm={loadForm}
          paginationSize={paginationSize}
          setPaginationSize={setPaginationSize}
        />
      ) : null}
    </Box>
  );
};

export default FormFields;
