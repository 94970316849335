import { Box } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import LLmModel from "./SelectLLmModel";

const LLMrelated = ({ editData }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Box px={4} py={2}>
      <Box mt="4">
        <LLmModel
          editData={editData}
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
        />
      </Box>
    </Box>
  );
};

export default LLMrelated;
