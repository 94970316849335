import { MdAssistant, MdKeyboardVoice } from "react-icons/md";
import { FaDatabase, FaCode } from "react-icons/fa";
import { SiTheconversation, SiJquery } from "react-icons/si";
export const analyticsConfig = {
  assistants_count: {
    label: "Assistants",
    icon: MdAssistant,
  },
  data_sources_count: {
    label: "Data Sources",
    icon: FaDatabase,
  },
  widgets_count: {
    label: "Widgets",
    icon: FaCode,
  },
  conversations_count: {
    label: "Conversations",
    icon: SiTheconversation,
  },
  queries_count: {
    label: "Queries",
    icon: SiJquery,
  },
  voice_queries_count: {
    label: "Voice Queries",
    icon: MdKeyboardVoice,
  },
};

export const getDashboardAnalyticsIcons = (type) => {
  const analytic = analyticsConfig[type];
  return analytic || analyticsConfig.conversations_count;
};


