import { useContext, useState } from "react";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";
import { fetchController } from "../../../utils/FetchController/fetchController";

export default function useFinalSubmit({ widgetForm }) {
  const baseUrl = getHostDomain();
  const { watch } = widgetForm;

  const agency = useContext(AgencyContext);
  const agency_id = agency.myDetails?.agency_id;
  const agency_name = agency.myDetails?.branding?.agency_name;
  const agency_app_domain = agency?.myDetails?.domain?.appDomain;
  const agency_logo_url = agency_id
    ? baseUrl + agency.myDetails?.branding?.logo_url
    : undefined;

  const [status, setStatus] = useState("idle");

  const getAttributesByWidgetType = (widget_type, data) => {
    const attributesMap = {
      chat: {
        initial_form_id: data?.form?.id || null,
      },
      whatsapp: {
        whatsapp_phone_number: data?.whatsapp_phone_number,
        phone_number_id: data?.phone_number_id,
        phone_business_id: data?.phone_business_id,
        whatsapp_access_token: data?.whatsapp_access_token,
        facebook_app_secret: data?.facebook_app_secret,
      },
      phone: {
        sid: data?.sid,
        phone_number: data?.phone_number,
        twilio_auth_id: data?.twilio_auth_id,
        phone_provider: "twilio",
        recording: data?.recording,
        max_call_duration: data?.max_call_duration,
      },
      html_call: {
        sid: data?.sid,
        phone_number: data?.phone_number,
        twilio_auth_id: data?.twilio_auth_id,
        phone_provider: "twilio",
        recording: data?.recording,
        max_call_duration: data?.max_call_duration,
        form_phone_number_field: data?.form_phone_number_field,
        form_id: data?.form?.id,
      },
      sms: {
        sid: data?.sid,
        phone_number: data?.phone_number,
        tool_user_id: data?.tool_user_id,
      },
      leadconnector: {
        location_id: data?.location_id,
        channels: data?.channels,
        tool_user_id: data?.tool_user_id,
      },
      leadconnector_call: {
        sid: data?.sid,
        phone_number: data?.phone_number,
        call_status: data?.call_status,
        twilio_auth_id: data?.twilio_auth_id,
        tool_user_id: data?.tool_user_id,
        location_id: data?.location_id,
      },
      fb_messenger: {
        fb_page_id: data?.fb_page_id,
        fb_page_name: data?.fb_page_name,
        tool_user_id: data?.tool_user_id,
      },
      instagram: {
        tool_user_id: data?.tool_user_id,
        ig_user_id: data?.ig_user_id,
        ig_name: data?.ig_name,
        ig_username: data?.ig_username,
      },
      plivo_call: {
        max_call_duration: data?.max_call_duration,
        recording: data?.recording,
        phone_number: data?.phone_number,
        tool_user_id: data?.tool_user_id,
      },
      telnyx_call: {
        max_call_duration: data?.max_call_duration,
        recording: data?.recording,
        phone_number_id: data?.attributes?.phone_number_id,
        tool_user_id: data?.attributes?.tool_user_id,
      },
      telegram: {
        tool_user_id: data?.tool_user_id,
      },
    };

    return attributesMap[widget_type] || {};
  };

  const handleSubmit = async (data) => {
    try {
      setStatus("loading");
      const body = {
        ...data,
        attributes: getAttributesByWidgetType(data.widget_type, data),
        user_opening_messages: [],
        action_buttons: [],
        style_params: {
          agency_name: agency_id ? agency_name : "Insighto.AI",
          agency_app_domain: agency_id
            ? agency_app_domain
            : "https://insighto.ai",
          agency_logo_url,
        },
      };

      const response = await fetchController(
        baseUrl + "/api/v1/widget",
        "POST",
        body
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setStatus("idle");
    }
  };

  return { status, handleSubmit, getAttributesByWidgetType };
}
