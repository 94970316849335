import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

const ShowJsonKeyValues = (props) => {
  const { isOpen, onClose, data, title } = props;

  const headerColNames = ["Key", "Value"];
  const keys = Object.keys(data);

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text color={"#3182ce"}>{title}</Text>
        </ModalHeader>
        <ModalCloseButton alignItems="center" />
        <ModalBody>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  {headerColNames.map((headerColumn) => (
                    <Th>{headerColumn}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {keys.map((key) => (
                  <Tr>
                    <Td>{key}</Td>
                    <Td>{data[key]}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="outline" width="104px">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShowJsonKeyValues;
