import {
  Button,
  Flex,
  Input,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { fetchController } from "../../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../../utils/utils";

export default function WhatsAppForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const baseUrl = getHostDomain();
  const { register, setValue } = widgetForm;
  const [details, setDetails] = useState([]);

  const fetchWhatsAppDetails = async () => {
    setValue("whatsapp_phone_number", details.whatsapp_phone_number);
    setValue("phone_number_id", details.phone_number_id);
    setValue("phone_business_id", details.phone_business_id);
    setValue("whatsapp_access_token", details.whatsapp_access_token);
    setValue("facebook_app_secret", details.facebook_app_secret);
  };

  const getWhatsAppDetails = async () => {
    try {
      const response = await fetchController(
        baseUrl + "/api/v1/user/list/UserWhatsApp?page=1&size=50",
        "GET"
      );

      if (response && response.data && response.data.items.length > 0) {
        const latestDetails =
          response.data.items[response.data.items.length - 1]; // Get the last item in the array

        if (latestDetails) {
          setDetails(latestDetails);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWhatsAppDetails();
  }, []);

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          {details && (
            <Button
              size="xs"
              fontSize="x-small"
              mb={2}
              onClick={fetchWhatsAppDetails}
            >
              Fetch Default WhatsApp Details
            </Button>
          )}
          <Input
            placeholder="WhatsApp Phone Number"
            type="number"
            {...register("whatsapp_phone_number", { required: true })}
            mb="4"
          />
          <Input
            placeholder="Phone Number ID"
            {...register("phone_number_id", { required: true })}
            mb="4"
          />
          <Input
            placeholder="Phone Business ID"
            {...register("phone_business_id", { required: true })}
            mb="4"
          />
          <Input
            placeholder="WhatsApp Access Token"
            {...register("whatsapp_access_token", { required: true })}
            mb="4"
          />
          <Input
            placeholder="Facebook App Secret"
            {...register("facebook_app_secret", { required: true })}
            mb="4"
          />
          <Flex justifyContent={"flex-start"}>
            <Link
              color={"blue.500"}
              href="https://developers.facebook.com/docs/whatsapp/cloud-api/get-started"
              isExternal
            >
              Get Keys <ExternalLinkIcon mx="2px" />
            </Link>
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
