import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import ViewMoreTextModal from "./ViewMoreTextModal";
import ShowJsonKeyValues from "./ShowJsonKeyValues";
import { MdDataObject } from "react-icons/md";

export default function SystemLogTable(props) {
  const {
    isOpen: isMessageModalOpen,
    onClose: closeMessageModal,
    onOpen: openMessageModal,
  } = useDisclosure();
  const [selectedLog, setSelectedLog] = useState(null);
  const headerColNames = ["Timestamp", "Message", "Attributes"];
  const messageSlicer = (message = "") => {
    const SLICE_LENGTH = 160;
    return {
      newMessage: message?.slice(0, SLICE_LENGTH),
      showViewMore: message.length > SLICE_LENGTH,
    };
  };

  const {
    isOpen: isAttributesModalOpen,
    onClose: closeAttributesModal,
    onOpen: openAttributesModal,
  } = useDisclosure();

  return (
    <TableContainer p={3} border={"1px solid lightgray"} borderRadius={"md"}>
      <Table size={"sm"} variant="striped">
        <Thead>
          <Tr>
            {headerColNames.map((headerColName) => (
              <Th key={headerColName}>{headerColName}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {props.logs.map((log) => {
            const { newMessage, showViewMore } = messageSlicer(log?.message);
            return (
              <Tr key={log.id}>
                <Td>
                  <Tooltip
                    label={moment(new Date(log?.created_at)).format(
                      "MMM Do, YYYY - HH:MM"
                    )}
                  >
                    {log?.created_at
                      ? moment.utc(log?.created_at).fromNow()
                      : null}
                  </Tooltip>
                </Td>
                <Td>
                  <Flex alignItems="center" gap={2}>
                    <Text noOfLines={1} justifyContent="flex-start">
                      {newMessage}
                    </Text>
                    {showViewMore && (
                      <Text
                        color="blue"
                        fontSize={"sm"}
                        onClick={() => {
                          setSelectedLog(log);
                          openMessageModal();
                        }}
                        _hover={{ cursor: "pointer" }}
                      >
                        ...View More
                      </Text>
                    )}
                  </Flex>
                </Td>
                <Td style={{ cursor: "pointer" }}>
                  <Box style={{ display: "flex" }}>
                    <Button
                      leftIcon={<MdDataObject />}
                      variant="outline"
                      colorScheme="blue"
                      onClick={() => {
                        setSelectedLog(log);
                        openAttributesModal();
                      }}
                      p="2"
                    >
                      {log?.attributes
                        ? Object.keys(log?.attributes).length
                        : 0}
                    </Button>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <ViewMoreTextModal
        isMessageModalOpen={isMessageModalOpen}
        closeMessageModal={closeMessageModal}
        title="Message"
        text={selectedLog?.message}
      />
      {isAttributesModalOpen && (
        <ShowJsonKeyValues
          isOpen={isAttributesModalOpen}
          onClose={closeAttributesModal}
          title="Attributes"
          data={selectedLog?.attributes}
        />
      )}
    </TableContainer>
  );
}
