import { Box, Flex, Tooltip, Checkbox, Text, Input } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import SelectConversationSync from "./SelectConversationSync";
import SelectContactSync from "./SelectContactSync";
import CustomCheckbox from "./CustomCheckbox";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
import { toast } from "react-toastify";

const ConversationFields = ({ member }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [selectedDropdown, setSelectedDropdown] = useState({
    supportedCRM: null,
    toolConfigurationToUse: null,
  });

  const agency = useContext(AgencyContext);
  const { buttonColorScheme, myDetails } = agency;
  const handleConversationCheck = (e) => {
    if (e.target.checked && !watch("attributes.contact"))
      return toast.error("Please Select Contact Sync ");

    setValue("attributes.conversation", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.tool_id", "");
      setValue("attributes.conversation_syncs", []);
    }
  };
  const handleContactCheck = (e) => {
    setValue("attributes.contact", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.contact_tool_id", "");
      setValue("attributes.contact_syncs", []);
      setValue("attributes.conversation", e.target.checked);
      setValue("attributes.conversation_syncs", []);
    }
  };
  const handleIsActiveCheck = (e) => {
    setValue("attributes.summarize.is_active", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.summarize.email", null);
    }
  };
  const toolTipMsg =
    myDetails && myDetails?.agency_id
      ? `Please contact ${myDetails?.branding?.agency_name || ""} `
      : "Please upgrade to Platinum or above";

  const toolTipMessage = {
    conversationSync:
      "Automatically pushes every conversation to the selected CRM within the contact to keep everything organized. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.",
    contactSync:
      "Automatically pushes every contact to the selected CRM to organize all contacts. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.",
  };

  return (
    <Box px={4} py={2}>
      <Text fontWeight="bold">CRM Sync</Text>
      <Flex gap={2}>
        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.conversation")}
            isChecked={watch("attributes.conversation")}
            onChange={handleConversationCheck}
          >
            <Tooltip label={toolTipMessage.conversationSync}>
              <Text>Conversation Sync</Text>
            </Tooltip>
          </CustomCheckbox>
          {watch("attributes.conversation") ? (
            <SelectConversationSync
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              selectedDropdown={selectedDropdown}
            />
          ) : null}
        </Box>

        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.contact")}
            isChecked={watch("attributes.contact")}
            onChange={handleContactCheck}
          >
            <Tooltip label={toolTipMessage.contactSync}>
              <Text>Contact Sync</Text>
            </Tooltip>
          </CustomCheckbox>
          {watch("attributes.contact") ? (
            <SelectContactSync
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
            />
          ) : null}
        </Box>
      </Flex>
      <Flex gap={3} mt={2}>
        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.summarize.is_active")}
            isChecked={watch("attributes.summarize.is_active")}
            onChange={handleIsActiveCheck}
          >
            <Text>Get summarized conversation ? (20 text credits)</Text>
          </CustomCheckbox>
        </Box>
        <Box width={"50%"}>
          {watch("attributes.summarize.is_active") && (
            <>
              <Input
                type="email"
                placeholder="Email"
                {...register("attributes.summarize.email", {
                  required: "This field is required",
                })}
              />
              {errors?.attributes?.summarize?.email && (
                <p style={{ color: "red", fontSize: "11px" }}>
                  {errors?.attributes?.summarize?.email?.message}
                </p>
              )}
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ConversationFields;
