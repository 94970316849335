import {
  Box,
  Flex,
  FormLabel,
  Grid,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { GiArtificialIntelligence } from "react-icons/gi";
import { SiDeepgram, SiElevenlabs } from "react-icons/si";
import { VscAzure } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Select from "react-select";
import CommonSlider from "../../utils/CommonSlider";
import IconWidgetOption from "../Playground/IconWidgetOption";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
import CustomCheckbox from "./CustomCheckbox";
import PreviewVoices from "./PreviewVoices";
import RealtimeOpenAiFields from "./RealtimeOpenAiFields";
import WhoSpeakFirst from "./WhoSpeakFirst";

const VoicesField = ({ myownVoiceOptions, languages, voices }) => {
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const recognizerSttProvider = {
    azure: VscAzure,
    deepgram: SiDeepgram,
  };
  const speakerTtsProvider = {
    azure: VscAzure,
    elevenlabs: SiElevenlabs,
    cartesia: GiArtificialIntelligence,
  };

  const languageOptions = languages.map((language) => ({
    ...language,
    Icon: recognizerSttProvider[language.voicestt_provider],
  }));

  const voiceLanguages = watch("voiceLanguages");
  const toolTipMessage = {
    interruptionTime: `Assistant will wait this long before interpreting & responding back to user’s speech. Suggested value 100ms. 
                              Higher value will let Assistant wait longer for letting the user finish. 
                              Lower value will help in responding quicker but might interrupt the user prematurely. 
                              Should be set higher (~2000ms) if you’re looking to extract long form information like email addresses`,
    recognizerConfidenceThreshold: `Set it to use a particular threshold for recognizing user’s speech - to remove any unwanted or background noise from the user’s phone. 
                      Suggested value: 0.4. High value will result in Assistant ignoring what user speaks`,
  };

  return (
    <Box px={4} py={2}>
      <Box>
        {watch("assistant_type") === "phone" ||
        watch("assistant_type") === "realtime_openai" ? null : (
          <CustomCheckbox
            mt="10px"
            {...register("voice")}
            isChecked={watch("voice")}
            onChange={(e) => setValue("voice", e.target.checked)}
          >
            Enable Voice
          </CustomCheckbox>
        )}
        {watch("voice") &&
          (watch("assistant_type") === "simple" ? (
            <>
              <FormLabel htmlFor="llmModel">
                Speech Language Recognizer
                <span style={{ fontSize: "12px" }}>{"(max 4)"}</span>
              </FormLabel>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={watch("voiceLanguages")}
                placeholder="Select Voice Languages"
                options={languageOptions}
                components={{ Option: IconWidgetOption }}
                isOptionDisabled={() =>
                  (watch("voiceLanguages") || []).length >= 4
                }
                isMulti
                {...register("voiceLanguages", {
                  required: "Voice Languages are required",
                })}
                onChange={(selectedOption) => {
                  setValue("voiceLanguages", selectedOption || null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
              {(watch("voiceLanguages") || []).length ? (
                <Box marginBlock={2}>
                  <Flex
                    marginBlock={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormLabel>Select Speaker Languages</FormLabel>
                  </Flex>
                  <Grid gap={2}>
                    {(watch("voiceLanguages") || []).map(
                      (lang, currentLangIndex) => (
                        <Grid
                          p={1}
                          borderRadius={"md"}
                          border={"1px solid lightgray"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gridTemplateColumns={"1fr 6fr"}
                          key={lang.locale_code}
                        >
                          <Text fontWeight={"bold"}>{lang.label}</Text>
                          <Box width={"100%"}>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              isOptionDisabled={(option) =>
                                option.voicetts_provider === "elevenlabs"
                              }
                              isDisabled={watch("custom_voice")}
                              value={lang.speaker || undefined}
                              onChange={(selectedSpeaker) => {
                                const currentSelectedLanguages =
                                  watch("voiceLanguages");
                                setValue(
                                  "voiceLanguages",
                                  currentSelectedLanguages.map((lang, index) =>
                                    currentLangIndex === index
                                      ? {
                                          ...lang,
                                          speaker: selectedSpeaker,
                                        }
                                      : lang
                                  )
                                );
                              }}
                              options={voices
                                .filter(
                                  (voiceItem) =>
                                    voiceItem.language_group ===
                                      "multilingual" ||
                                    voiceItem.language_group ===
                                      lang.language_group
                                )
                                .map((voicePerson) => ({
                                  label: `${voicePerson.name} (${voicePerson.credits} credits)`,
                                  value: voicePerson.id,
                                  voicetts_provider:
                                    voicePerson.voicetts_provider,
                                  Icon: speakerTtsProvider[
                                    voicePerson.voicetts_provider
                                  ],
                                }))}
                              components={{ Option: IconWidgetOption }}
                            />
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ) : null}
            </>
          ) : watch("assistant_type") === "realtime_openai" ? null : (
            <>
              <FormLabel htmlFor="voiceLanguages">
                <b>Speech Language Recognizer</b>
                <span style={{ fontSize: "12px" }}>{"(max 1)"}</span>
              </FormLabel>
              <Select
                value={watch("voiceLanguages")}
                placeholder="Select Voice Languages"
                options={languageOptions}
                components={{ Option: IconWidgetOption }}
                isOptionDisabled={() =>
                  (watch("voiceLanguages") || []).length >= 1
                }
                isMulti
                {...register("voiceLanguages", {
                  required: "Voice Languages are required",
                })}
                onChange={(selectedOption) => {
                  const handleSetValuesForSTT = {
                    deepgram: () => {
                      setValue("attributes.interruption_time", 100);
                    },
                    azure: () => {
                      setValue("attributes.recognizer_confidence", 0.4);
                      setValue("attributes.interruption_time", 100);
                    },
                  };

                  const handleSetValues =
                    handleSetValuesForSTT[
                      selectedOption?.[0]?.voicestt_provider
                    ] || function () {};
                  handleSetValues();

                  setValue("voiceLanguages", selectedOption || null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
              {(watch("voiceLanguages") || []).length ? (
                <Box marginBlock={2}>
                  <Flex
                    marginBlock={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormLabel>
                      <b>Select Speaker Languages</b>
                    </FormLabel>
                    <PreviewVoices ttsList={voices} />
                  </Flex>
                  <Grid gap={2}>
                    {(watch("voiceLanguages") || []).map(
                      (lang, currentLangIndex) => (
                        <Grid
                          p={1}
                          borderRadius={"md"}
                          border={"1px solid lightgray"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gridTemplateColumns={"1fr 6fr"}
                          key={lang.locale_code}
                        >
                          <Text fontWeight={"bold"}>{lang.label}</Text>
                          <Box width={"100%"}>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              isDisabled={watch("custom_voice")}
                              value={lang.speaker || undefined}
                              onChange={(selectedSpeaker) => {
                                const currentSelectedLanguages =
                                  watch("voiceLanguages");

                                setValue(
                                  "voiceLanguages",
                                  currentSelectedLanguages.map((lang, index) =>
                                    currentLangIndex === index
                                      ? {
                                          ...lang,
                                          speaker: selectedSpeaker,
                                        }
                                      : lang
                                  )
                                );
                              }}
                              options={voices
                                .filter(
                                  (voiceItem) =>
                                    voiceItem.language_group ===
                                      "multilingual" ||
                                    voiceItem.language_group ===
                                      lang.language_group
                                )
                                .map((voicePerson) => ({
                                  label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                                  value: voicePerson.id,
                                  voicetts_provider:
                                    voicePerson.voicetts_provider,
                                  Icon: speakerTtsProvider[
                                    voicePerson.voicetts_provider
                                  ],
                                }))}
                              components={{ Option: IconWidgetOption }}
                            />
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ) : null}
            </>
          ))}
        {errors.voiceLanguages && (
          <Text fontSize="11px" color="red" mt="1">
            {errors.voiceLanguages.message}
          </Text>
        )}
      </Box>
      <Flex gap={2}>
        <Grid gap={2} width="50%" mb="auto">
          {watch("assistant_type") === "realtime_openai" ? null : (
            <>
              <CustomCheckbox
                isChecked={watch("custom_voice")}
                onChange={(e) => setValue("custom_voice", e.target.checked)}
                isDisabled={!myownVoiceOptions.length}
              >
                Use My Own Voice
              </CustomCheckbox>
              <Text>
                {!myownVoiceOptions.length ? (
                  <Link
                    to={`/settings`}
                    style={{ textDecoration: "none", fontSize: "14px" }}
                    _hover={{ color: "inherit" }}
                  >
                    (Create your Own Voice)
                  </Link>
                ) : null}
              </Text>
            </>
          )}
        </Grid>
        <Grid width="50%">
          {watch("assistant_type") === "phone" && (
            <WhoSpeakFirst
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              register={register}
            />
          )}
        </Grid>
      </Flex>

      {watch("assistant_type") === "phone" && (
        <>
          <Flex gap={2} mt="1">
            <Grid width="50%">
              <FormLabelWithInfoTooltip
                label={
                  <div>
                    <b>Listen Silence </b>
                    <span style={{ fontSize: "12px" }}>{"(in seconds)"}</span>
                  </div>
                }
                tip="Define the duration of inactivity (in seconds) after which the Assistant will automatically speak. If no input is detected from the user within this time, the bot will begin speaking to prompt or continue the conversation."
              />

              <NumberInput
                value={watch("attributes.listen_silence")}
                min={5}
                onChange={(valueString) => {
                  const value = Number(valueString);
                  setValue("attributes.listen_silence", value);
                }}
              >
                <NumberInputField
                  placeholder="Listen Silence"
                  {...register("attributes.listen_silence", {
                    required: "Listen Silence is required.",
                    valueAsNumber: true,
                    validate: (value) => {
                      if (value && value < 0) {
                        return "Listen Silence can't be negative.";
                      } else if (!value) return "Listen Silence is required.";
                    },
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Grid>
            <Grid width="50%">
              <FormLabelWithInfoTooltip
                label={<b>What to speak if user stays silent</b>}
                tip=" Enter the text the Assistant will speak after the silence duration is reached"
              />
              <Input
                type="text"
                placeholder="Hey, are you still there?"
                {...register("attributes.listen_silence_message")}
                disabled={
                  !watch("attributes.listen_silence") ||
                  watch("attributes.listen_silence") <= 0
                }
              />
            </Grid>
          </Flex>
          {errors.attributes?.listen_silence && (
            <Text color="red.500" mt={1}>
              {errors.attributes.listen_silence.message}
            </Text>
          )}
          <Flex gap={4} mt="2">
            <Grid width="50%">
              <CommonSlider
                watch={watch}
                label={<b>Interruption Time</b>}
                setValue={setValue}
                min={10}
                max={1000}
                defaultValue={100}
                name="attributes.interruption_time"
                step={1}
                markLabel="ms"
                ml="-12%"
                toolTipLabel={toolTipMessage.interruptionTime}
              />
            </Grid>

            {voiceLanguages.length > 0 &&
              ["azure"].includes(voiceLanguages[0]?.voicestt_provider) && (
                <Grid width="50%">
                  <CommonSlider
                    watch={watch}
                    label={<b>Recognizer Confidence Threshold</b>}
                    setValue={setValue}
                    min={0}
                    max={1}
                    defaultValue={0.5}
                    name="attributes.recognizer_confidence"
                    step={0.1}
                    toolTipLabel={toolTipMessage.recognizerConfidenceThreshold}
                  />
                </Grid>
              )}
          </Flex>
        </>
      )}

      {watch("assistant_type") === "realtime_openai" ? (
        <RealtimeOpenAiFields setValue={setValue} watch={watch} />
      ) : null}
    </Box>
  );
};

export default VoicesField;
