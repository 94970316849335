import { Button, Grid, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import useExternalAuth from "../../../Tools/useExternalAuth";
import SMSFields from "../../SMSFields";

export default function SMSForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { watch, setValue } = widgetForm;

  const { auths } = useExternalAuth({
    auth_provider: "twilio",
  });

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <Grid>
            <SMSFields auths={auths} setValue={setValue} watch={watch} />
          </Grid>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
