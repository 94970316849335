import { BaseEdge, EdgeLabelRenderer, getStraightPath } from "@xyflow/react";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}) {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const borderColor = data.type === "variables" ? "blue" : "green";

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: borderColor,
          strokeDasharray: data.type ? "0" : "5,5",
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            position: "absolute",
            backgroundColor: "#fff",
            border: `1px solid ${borderColor}`,
            padding: "10px",
            borderRadius: "3px",
            fontSize: "8px",
            fontWeight: "500",
          }}
          className="nodrag nopan"
        >
          {data?.name}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
