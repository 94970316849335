import { Select } from "@chakra-ui/react";

export default function PaginationSizeSelect({
  paginationSize,
  setPaginationSize,
  tableLength,
}) {
  return (
    <>
      {tableLength >= 10 && (
        <Select
          value={paginationSize}
          onChange={(e) => {
            setPaginationSize(e.target.value);
          }}
          width={"auto"}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Select>
      )}
    </>
  );
}
