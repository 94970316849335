import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useConversationFlow from "./hooks/useConversationFlow";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default function NewConversationFlowModal({ isOpen, onClose, selectedFlow }) {
  const navigate = useNavigate();
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { addFlow, getFlowById } = useConversationFlow();
  const [payload, setPayload] = useState({
    name: "",
    num_nodes: 1,
    num_edges: 0,
    flow: {
      nodes: [
        {
          data: {
            node_type: "start",
            name: "Start",
            label: "Start",
            backgroundColor: "#3182CE",
            textColor: "#fff",
          },
          id: `${Date.now()}`,
          type: "customNode",
          position: {
            x: 0,
            y: 0,
          },
        },
      ],
      edges: [],
    },
  });

  const handleClose = () => {
    onClose();
  };

  const createDuplicateFlowPayload = async (id) => {
    try {
      const response = await getFlowById(id);

      const { name, flow, num_nodes, num_edges } = response.data;
      setPayload({ name, flow, num_nodes, num_edges })
    } catch (error) {
      toast.error(error?.message || "An unexpected error occurred");
      handleClose()
    }
  };

  useEffect(() => {
    if(selectedFlow) createDuplicateFlowPayload(selectedFlow.id)
  }, [selectedFlow])

  const onSubmit = async (data) => {
    try {
      const body = {
        ...payload,
        name: data.name,
      };

      const response = await addFlow(body);

      toast.success(
        response?.message || "Conversation flow created successfully"
      );
      navigate(`/conversation-flow/${response?.data?.id}`);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
      handleClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create new conversation flow</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter conversation flow name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme="yellow">
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
