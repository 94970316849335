import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";

export default function WidgetStep({ widgetForm, onSubmit, onBack, providers }) {
  const { register, watch, setValue, formState } = widgetForm;
  const { errors } = formState;

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Grid
          gridTemplateColumns={"auto 1fr"}
          alignItems={"center"}
          marginBlock={4}
        >
          <Text mr={2} fontWeight="bold">
            Widget Types
          </Text>
          <Flex width={"100%"} justifyContent={"flex-start"} gap={3}>
            {providers[watch("provider")].widgetTypes.map((widget) => (
              <Tooltip key={widget.value} label={widget.label}>
                <Button
                  colorScheme={
                    watch("widget_type") === widget.value ? "blue" : "gray"
                  }
                  onClick={() => setValue("widget_type", widget.value)}
                >
                  {typeof widget.icon === "string" ? (
                    <img src={widget.icon} width={15} />
                  ) : (
                    widget.icon
                  )}
                </Button>
              </Tooltip>
            ))}
          </Flex>
        </Grid>

        <FormControl isInvalid={errors?.name}>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Enter widget name"
            {...register("name", { required: "Name is required" })}
          />
          <FormErrorMessage>
            {errors?.name && errors?.name?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors?.description}>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="only for your reference, not used as part of the prompt"
            {...register("description", {
              required: "Description is required",
            })}
            resize="vertical"
          />
          <FormErrorMessage>
            {errors?.description && errors?.description?.message}
          </FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          isDisabled={!watch("widget_type")}
          type="submit"
          colorScheme={"yellow"}
        >
          {"Next"}
        </Button>
      </ModalFooter>
    </form>
  );
}
