import { Flex, Icon, Link, Menu, MenuButton, Text, theme, useColorMode, useColorModeValue, useTheme } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function NavItem({
  icon,
  title,
  active,
  navSize,
  toolTip,
  size = "large",
  isDisabled = false
}) {
  const iconSize = size === "small" ? "sm" : "xl";
  const bg = useColorModeValue("gray.100");


  const agency = useContext(AgencyContext);
  const textColor = agency?.textColor
  const iconColor = useColorModeValue(textColor ||theme.colors.gray[500],"white");
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex
      transition={"300ms ease-in all"}
      _hover={{
        bg
      }}
      fontSize={"md"}
      borderRight={active && `3px solid ${iconColor}`}
      flexDir="column"
      w="100%"
      alignItems={navSize === "small" ? "center" : "flex-start"}
    >
      <Menu placement="right">
        <Link
          title={toolTip || title}
          p={iconSize === "small" ? 1 : 3}
          borderRadius={8}
          w={navSize === "large" && "100%"}
        >
          <MenuButton w="100%" disabled={isDisabled}>
            <Flex alignItems={"center"}>
              <Icon as={icon} fontSize={iconSize} color={iconColor} />
              <Text
                fontWeight={"bold"}
                color={active ? colorMode === "light" ? textColor || "black" : "white" :  "grey"}
                ml={5}
                display={navSize === "small" ? "none" : "flex"}
              >
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
}
