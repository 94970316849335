import {
  Button,
  Flex,
  Grid,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import React from "react";
export default function NodeTypeSelectForm({
  onClose,
  nodeForm,
  nodeTypeOptions,
  onSubmit,
}) {
  const { form } = nodeForm;

  const { data = {} } = nodeForm.form.watch();

  return (
    <>
      <ModalBody>
        <Grid
          gap={3}
          gridTemplateColumns={"1fr 1fr"}
          spacing={2}
          width={"100%"}
        >
          {nodeTypeOptions.map((nodeTypeOption) => (
            <Flex
              cursor={nodeTypeOption.value === "webhook" ? "not-allowed" : "pointer"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDir={"column"}
              key={nodeTypeOption.value}
              _hover={{
                backgroundColor: "gray.50",
              }}
              onClick={() => {
                if(nodeTypeOption.value !== "webhook") form.setValue("data.node_type", nodeTypeOption.value);
              }}
              backgroundColor={
                data.node_type === nodeTypeOption.value
                  ? "gray.100"
                  : undefined
              }
              opacity={nodeTypeOption.value === "webhook" ? "0.5" : ""}
              border={"1px solid lightgray"}
              p={2}
              borderRadius={"md"}
            >
              <nodeTypeOption.Icon size={50} />
              <Text>{nodeTypeOption.label}</Text>
            </Flex>
          ))}
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button mr={3} onClick={onClose}>
          Close
        </Button>
        <Button
          onClick={onSubmit}
          isDisabled={!data.node_type}
          colorScheme="yellow"
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}
