import {
  Button,
  Flex,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
} from "@chakra-ui/react";
import CommonWidgetTypeForm from "./CommonWidgetTypeForm";
import SelectTwilioAccount from "../../SelectTwilioAccount";
import SelectPhoneNumber from "../../SelectPhoneNumber";
import useTwilioAccounts from "../../useTwilioAccounts";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function PhoneForm({
  widgetForm,
  onSubmit,
  loadingStatus,
  onBack,
}) {
  const { setValue, watch, formState } = widgetForm;
  const { errors } = formState;

  const { accounts, phoneNumbers } = useTwilioAccounts({
    widgetType: watch("widget_type"),
    twilio_auth_id: watch("twilio_auth_id"),
  });

  return (
    <form onSubmit={widgetForm.handleSubmit(onSubmit)}>
      <ModalBody>
        <Stack spacing={2}>
          <CommonWidgetTypeForm widgetForm={widgetForm} />
          <SelectTwilioAccount
            accounts={accounts}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
          <SelectPhoneNumber
            watch={watch}
            setValue={setValue}
            phoneNumbers={phoneNumbers}
          />
          <Flex justifyContent={"flex-start"}>
            <Link
              color={"blue.500"}
              href={
                "https://www.twilio.com/en-us/blog/better-twilio-authentication-csharp-twilio-api-keys"
              }
              isExternal
            >
              Get Keys <ExternalLinkIcon mx="2px" />
            </Link>
          </Flex>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          isLoading={loadingStatus === "loading"}
          colorScheme={"blue"}
        >
          {"Finish"}
        </Button>
      </ModalFooter>
    </form>
  );
}
