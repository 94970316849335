/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaMixcloud, FaPhoneAlt, FaSms } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoLogoWhatsapp } from "react-icons/io5";
import { PiFileHtml } from "react-icons/pi";
import { TbMessageChatbot } from "react-icons/tb";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import AddWidget from "../Modals/AddWidgets";
import Table from "../Tables/Table";
import WidgetTour from "./WidgetTour";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { FiPhoneMissed } from "react-icons/fi";
import { FaFacebookMessenger, FaInstagram } from "react-icons/fa6";
import Paginator from "../../utils/Loader/Paginator";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";
import { BiLogoTelegram } from "react-icons/bi";
import AddWidgetModal from "./AddWidget/AddWidgetModal";
const Widgets = ({ tab }) => {
  const baseUrl = getHostDomain();
  const ghlLogo =
    "https://images.saasworthy.com/tr:w-160,h-0,c-at_max,e-sharpen-1/highlevel_29566_logo_1712921952_nwbzw.png";
  const providers = {
    phone: {
      label: "Twilio",
      icon: <img src="/icons/twilio.svg" width={25} />,
    },
    sms: {
      label: "SMS",
      icon: <img src="/icons/twilio.svg" width={25} />,
    },
    whatsapp: {
      label: "WhatsApp",
      icon: <img src="/icons/whatsapp.svg" width={25} />,
    },
    leadconnector: {
      label: "GHL Connect",
      icon: <img src={ghlLogo} width={25} />,
    },
    leadconnector_call: {
      label: "GHL Connect",
      icon: <img src={ghlLogo} width={25} />,
    },
    instagram: {
      label: "Instagram",
      icon: <img src="/icons/instagram.svg" width={25} />,
    },
    plivo_call: {
      label: "Plivo Call",
      icon: <img src="/icons/plivo.svg" width={25} />,
    },
    telnyx_call: {
      label: "Telnyx Call",
      icon: <img src="/icons/telnyx.svg" width={25} />,
    },
    telegram: {
      label: "Telegram",
      icon: (
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png"
          width={25}
        />
      ),
    },
    fb_messenger: {
      label: "Facebook Messenger",
      icon: (
        <img
          src="https://cdn4.iconfinder.com/data/icons/social-media-2285/1024/logo-512.png"
          width={25}
        />
      ),
    },
  };

  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);

  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;

  const endpoint = `/api/v1/widget/get_my_details?size=${paginationSize}&page=${page}`;

  const agencyLogoUrl = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.logo_url
    : null;
  const makeAgencyLogourl = (logo_url) => {
    if (!logo_url) return;
    return logo_url.includes(window.origin)
      ? logo_url
      : `${window.location.origin}${logo_url}`;
  };
  const logo = agency?.myDetails?.agency_id
    ? makeAgencyLogourl(agencyLogoUrl)
    : "/logo/favicon.png";
  const name = agency?.myDetails?.agency_id
    ? agency?.myDetails?.branding?.agency_name
    : "Insighto.AI";
  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + endpoint;

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);

      const updatedData = getData.data.items.map((item) => {
        const provider = providers[item?.widget_type] || {
          label: name,
          icon: <img src={logo} width={25} />,
        };

        return {
          type: (
            <Box ml={2}>
              {item.widget_type === "html" ? (
                <PiFileHtml />
              ) : item.widget_type === "html_call" ? (
                <MdOutlineWebAsset />
              ) : item.widget_type === "whatsapp" ? (
                <IoLogoWhatsapp />
              ) : item.widget_type === "phone" ? (
                <FaPhoneAlt />
              ) : item.widget_type === "sms" ? (
                <FaSms />
              ) : item.widget_type === "leadconnector" ? (
                <MdOutlineLeaderboard />
              ) : item.widget_type === "leadconnector_call" ? (
                <FiPhoneMissed />
              ) : item.widget_type === "fb_messenger" ? (
                <FaFacebookMessenger />
              ) : item.widget_type === "web_call" ? (
                <MdOutlineWifiCalling3 />
              ) : item.widget_type === "instagram" ? (
                <FaInstagram />
              ) : item.widget_type === "plivo_call" ? (
                <FaMixcloud />
              ) : item.widget_type === "telnyx_call" ? (
                <img src="/icons/telnyx.svg" width={15} />
              ) : item.widget_type === "telegram" ? (
                <BiLogoTelegram />
              ) : (
                <TbMessageChatbot />
              )}
            </Box>
          ),
          status:
            item.status ||
            item.widget_type === "phone" ||
            item.widget_type === "html_call" ||
            item.widget_type === "leadconnector" ||
            item.widget_type === "leadconnector_call" ||
            item.widget_type === "sms" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "15px",
                }}
              >
                <Tooltip label="Live" aria-label="Live">
                  <Center>
                    <Icon as={GoDotFill} color="green.500" />
                  </Center>
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "15px",
                }}
              >
                <Tooltip label="Add Data Sources" aria-label="Add Data Sources">
                  <Center>
                    <Icon as={GoDotFill} color="red.500" />
                  </Center>
                </Tooltip>
              </div>
            ),
          assistant_id: item.assistant_id,
          assistant: item.assistant_name,
          ["data_Source"]: (
            <div style={{ marginLeft: "35px" }}>
              {item.widget_type === "phone"
                ? "-"
                : item.data_sources_count.toLocaleString()}
            </div>
          ),
          name: (
            <Box>
              <Text>{item.name}</Text>
              {item?.provider_user_friendly_label && (
                <Text fontSize={"xs"} fontStyle={"italic"}>
                  {item?.provider_user_friendly_label}
                </Text>
              )}
            </Box>
          ),
          description: item.description,
          widget_type: item.widget_type,
          id: item.id,
          provider: (
            <Tooltip label={provider.label}>
              <Box ml={4}>{provider.icon}</Box>
            </Tooltip>
          ),
        };
      });
      const heads = [
        "type",
        "provider",
        "name",
        "description",
        "assistant",
        "data_Source",
      ];
      const columns = heads.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
      }));
      setTableData(updatedData);
      setHeads(columns);
      setStatus("success");
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (tab === "Widgets") {
      hitFetch();
    }
  }, [tab, page, paginationSize]);

  const loading = status === "loading" && isInitialLoad;
  const [widgetTourVisibility, setWidgetTourVisibility] = useState(
    localStorage.getItem("widgetTourVisibility") ? false : true
  );
  const onCloseWidgetTour = () => {
    localStorage.setItem("widgetTourVisibility", false);
    setWidgetTourVisibility(false);
  };

  const onGotoFirstPage = () => setPage(1);

  return (
    <>
      <Box
        border="1px"
        p="5"
        height={"81vh"}
        overflowY="scroll"
        borderRadius="md"
      >
        <Heading color={textColor} fontSize="xl">
          Widgets
        </Heading>
        {widgetTourVisibility ? (
          <WidgetTour onCloseWidgetTour={onCloseWidgetTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
          >
            Add a Widget
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="widget"
            />

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
              gap={2}
            >
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      {/* <AddWidget
        fetchAssistants={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onGotoFirstPage={onGotoFirstPage}
      /> */}
      {isOpen && (
        <AddWidgetModal
          isOpen={isOpen}
          onClose={onClose}
          fetchWidgets={hitFetch}
          onGotoFirstPage={onGotoFirstPage}
        />
      )}
    </>
  );
};

export default Widgets;
